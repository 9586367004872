import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormSelect,
  CButton,
  CListGroup,
  CListGroupItem,
  CBadge,
  CFormLabel,
 
} from '@coreui/react'
import Select from 'react-select';
import AppSidebar from 'src/components/AppSidebar';
import AppHeader from 'src/components/AppHeader';
// import AppFooter from 'src/components/AppFooter';
import { getApi, postApi } from 'src/api/Api';
import { API_PATH, Loader, MyMarquee, notifyError, notifySuccess, } from 'src/utils/constants';
import CIcon from '@coreui/icons-react';
import { cilTrash,cilCheck } from '@coreui/icons';

const dummydata =[
    {
        "itemId": "1",
        "quantity": 0,
        "itemUnitType": "Kilogram",
        "itemName": "Item1"
    },
    {
        "itemId": "2",
        "quantity": 0,
        "itemUnitType": "Liter",
        "itemName": "Item2"
    },
    {
        "itemId": "3",
        "quantity": 0,
        "itemUnitType": "Box",
        "itemName": "Item3"
    }
]

export const TransferRequest = () => {
    const [selelctRestaurant, setselelctRestaurant] = useState(null);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [restaurantOption, setrestaurantOption] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [orgoptions, setorgOptions] = useState([]);
    const [type, setTypes] = useState([])
    const [orderItems, setorderItems] = useState([])
    const [search, setSearch] = useState(null)
    const [loading, setLoading] = useState(false)

    const [spinner ,setSpinner] =useState(false)
  
  
    const [inputField, setInputField] = useState({
      "itemId": "",
      "quantity": 0,
      "unitType": "",
      "itemName": ""
    })
  
    const inputsHandler = (e) => {
      const { name, value } = e.target;
      setInputField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  
    const AddItem = (e,data) => {
      e.preventDefault();
  
        try {
          data.quantity = data.quantity.toString();
          const newState = options.map(obj => {
            if (obj.itemId === data.itemId) {
              return { ...obj, added: 1 };
            }
            return obj;
          });
          const newState2 = orgoptions.map(obj => {
            if (obj.itemId === data.itemId) {
              return { ...obj, added: 1 };
            }
            return obj;
          });
          setOptions(newState);
          setorgOptions(newState2)
          setorderItems(oldArray => [...oldArray, data]);
          // notifySuccess("Item Added Successfully !")
        } catch (error) {
          console.log(error)
          notifyError("Error")
        }
  
    }
    const onSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const items = orderItems.map(item => {
  
          // delete item.categoryId
          // delete item.unitType
          // delete item.itemName
          // delete item.added
          // delete item.itemUnitType
          return {
            "transferItemId": item.itemId,
            "actualQuantity": item.quantity
          }
        })
        const data = {
          "restaurantId": selelctRestaurant.restaurantId,
          // "date": date,
          "transferItems": items
        }
        console.log(data)
        const res = await postApi(API_PATH.SUPER_ADMIN.TRANSFER.transferOrder, data);
        if (res.status == 200) {
          notifySuccess("Order Successfully !")
          setorderItems([])
          setOptions([])
          getItems()
        }
  
      } catch (error) {
        console.log(error)
        notifyError("Error")
      }
  
    }
  
    useEffect(()=>{
        getRestaurants();
       
      },[])

      useEffect(()=>{
        if(selelctRestaurant!=null){
          getItems()
        }
        
      },[selelctRestaurant])
    
      async function getRestaurants() {
          try {
            setSpinner(true)
            const res = await getApi(`${API_PATH.SUPER_ADMIN.RESTAURANT}`)
            if(res.status==200 && res.data.data.restaurants.length){
                const data = res.data.data.restaurants
    
                for (let index = 0; index < data.length; index++) {
                    data[index].value = data[index].restaurantId;
                    data[index].label = data[index].restaurantName;
                  }
              setrestaurantOption(data)
            }
          } catch (error) {
            setrestaurantOption([])
            setSpinner(false)
          }
      }

      async function getItems() {
          try {
            setSpinner(true)
            const res = await getApi(`${API_PATH.SUPER_ADMIN.TRANSFER.transferItem}`)
            if(res.status==200 && res.data.data.transferItems.length){
                const data = res.data.data.transferItems
                for (let index = 0; index < data.length; index++) {
                  data[index].quantity = 0;
                  data[index].added = 0;
                }
                setorgOptions(data)
                setOptions(data)
            }
          } catch (error) {
            setrestaurantOption([])
            setSpinner(false)
          }
      }

    function onChangeCat(id) {
      let itemlist = type.items.filter((e) => e.categoryId == id);
      setOptions([])
      setorderItems([])
      for (let index = 0; index < itemlist.length; index++) {
        itemlist[index].quantity = 0;
        itemlist[index].added = 0;
      }
      setOptions(itemlist)
      setorgOptions(itemlist)
    
  
    }
    useEffect(() => {
      if (selectedOption != null) {
        setInputField((prevState) => ({
          ...prevState,
          ['unitType']: selectedOption.itemUnitType,
          ['itemId']: selectedOption.itemId,
          ['itemName']: selectedOption.itemName,
        }));
      }
    }, [selectedOption])
  
  
    useEffect(()=>{
      if(search){
  
        const filteredItems = orgoptions.filter(
          item => item.itemName && item.itemName.toLowerCase().includes(search.toLowerCase()),
        );
        setOptions(filteredItems);
      }
      if(search==""){
        setOptions(orgoptions)
      }
     
    },[search])

    function increment(id) {
        const newState = options.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (parseInt(obj.quantity)!=NaN ?  parseInt(obj.quantity)+ 1 : 1).toString()};
          }
          return obj;
  
        });
        const newState2 = orgoptions.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (parseInt(obj.quantity) + 1).toString()};
          }
          return obj;
  
        });
        setOptions(newState);
        setorgOptions(newState2)
    }

    function onChnageValue(value,id) {
        const newState = options.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (value).toString()};
          }
          return obj;
  
        });
        const newState2 = orgoptions.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (value).toString()};
          }
          return obj;
  
        });
        setOptions(newState);
        setorgOptions(newState2)
    }
  
    function decrement(id) {
        const newState = options.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (parseInt(obj.quantity) > 0 ? parseInt(obj.quantity) - 1 : 1) };
          }
          return obj;
        });
        const newState2 = orgoptions.map(obj => {
          if (obj.itemId === id) {
            return { ...obj, quantity: (parseInt(obj.quantity) > 0 ? parseInt(obj.quantity) - 1 : 1) };
          }
          return obj;
        });
  
        setOptions(newState);
        setorgOptions(newState2)
    }
  
    function deleteItem(id) {
      setorderItems(orderItems.filter((i) => (i.itemId !== id)))
      const newState = options.map(obj => {
        if (obj.itemId === id) {
          return { ...obj, added: 0 };
        }
        return obj;
      });
      const newState2 = orgoptions.map(obj => {
        if (obj.itemId === id) {
          return { ...obj, added: 0 };
        }
        return obj;
      });
      setOptions(newState);
      setorgOptions(newState2)
    }
    return (
  
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
         
            <CRow>
              <CCol xl={6}  >
                <CCard className="mb-4">
                  <CCardHeader>
                    <strong>Order Item</strong>
                  </CCardHeader>
                  <CCardBody>
                    <CForm className="row g-3" onSubmit={AddItem}>
  
                      <CCol md={12}>
                      <Select
                  placeholder={"Select Restaurant"}
                  defaultValue={selelctRestaurant}
                  onChange={setselelctRestaurant}
                  options={restaurantOption}
                  />
                      </CCol>
                      <CCol>
                      {/* <CDatePicker date="2022/2/16" label="Date" locale="en-US" /> */}
                      {/* <CFormInput type="date" id="inputEmail4" value={date} min={new Date().toISOString().split('T')[0]} onChange={(e)=>setDate(e.target.value)} /> */}
                      {/* <CFormLabel htmlFor="floatingPassword">Password</CFormLabel>
                      <input type="date"></input> */}
                      </CCol>
                     {options.length>0 ?  <CCol md={12}>
                        <CFormInput placeholder='Filter by item name' onChange={(e)=>setSearch(e.target.value)}/>
                        </CCol> : null}
                      <CCol md={12}>
                      {/* <CListGroup> */}
                    {options && options.map((d, index) => (
                      <CRow className='mb-1'>
                        <CCol md={7}>
                        <div > {d.itemName} ({d.unitType})</div>
                        </CCol>
                        <CCol md={5}>
                          
                        <CButton color="light" disabled={parseInt(d.quantity) == 0 || d.added==1 ? true : false} style={{ marginLeft: "5px", color: "black", padding: "6px 13px" }} onClick={() => decrement(d.itemId)}>-</CButton>
                        <CFormInput className='custom_input' onChange={(e)=>onChnageValue(e.target.value,d.itemId)} value={d.quantity}></CFormInput>
                        {/* <input type="text"/> */}
                         <CButton disabled={d.added==1} color="light" style={{ marginRight: "5px", color: "black", padding: "6px 13px" }} onClick={() => increment(d.itemId)} >+</CButton>
                        <CButton style={{width:"34%"}} onClick={(e)=> d.quantity>0 && AddItem(e,d)} disabled={d.added==1}>Add {d.added==1 && <CIcon icon={cilCheck}></CIcon> }</CButton>
                        </CCol>
                      </CRow>
                     
                    ))}
                  
                      </CCol>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol xl={6} >
                <CCard>
                  <CCardHeader>
                    <strong>Your Order</strong>
                  {selelctRestaurant &&  <span className='m-5'>{selelctRestaurant.restaurantName}
                  {/* <span className='m-3'>{date}</span> */}
                  </span>}
                  </CCardHeader>
                  <CListGroup>
                    {orderItems.length>0 ? orderItems.map((d, index) => (
                      <CListGroupItem className="d-flex justify-content-between align-items-center m-1" style={{ border: "none", borderBottom: "1px solid rgba(0, 0, 21, 0.125)", padding: "0 10px 5px 10px" }}>
  
                        <div style={{ width: "5%" }}><p className='m-0'>{index+1}.</p></div>
                        <div style={{ width: "55%" }}><p className='m-0 fw-bolder'> {d.itemName}</p></div>
                        <div style={{ width: "37%" }} className='justify-content-between'>
                        <CRow>
                          <CCol md={7}>
                          <CBadge color="info" >{d.quantity} {d.unitType}</CBadge>
                            
                          </CCol>
                          <CCol md={5}>
                          <CIcon icon={cilTrash} style={{ color: "#d04242", cursor: "pointer" }} onClick={() => deleteItem(d.itemId)} />
  
                          </CCol>
                        </CRow>
                          {/* <CButton color="light" style={{ marginRight: "5px", color: "black", padding: "0 10px 0 10px" }} onClick={() => increment(2)} >+</CButton> */}
                          {/* <CButton color="light" disabled={parseInt(d.quantity) > 1 ? false : true} style={{ marginLeft: "5px", color: "black", padding: "0 10px 0 10px" }} onClick={() => parseInt(d.quantity) > 1 && decrement(2)}>-</CButton> */}
                        </div>
                      </CListGroupItem>
                    )): <h6 className='text-center m-5'>No Items</h6>}
                    {orderItems.length > 0 && <CCol xl={4} className="m-2"><CButton width="100%" type='button' onClick={onSubmit}>Place Order</CButton></CCol>}
                  </CListGroup>
                </CCard>
              </CCol>
            </CRow>
          </div>
          {/* <AppFooter /> */}
          {loading == true && <Loader status={loading} />}
        </div>
      </div>
  
    )
}
