import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton, CCardHeader, CFormInput, CForm, CFormSelect, CFormCheck
} from '@coreui/react'
import {
  cilPen, cilTrash
} from '@coreui/icons'
import { DocsExample } from 'src/components'
import DataTable from 'react-data-table-component';
import CIcon from '@coreui/icons-react';
import ReactModal from 'react-modal';
import FilterComponent from 'src/views/FilterComponent';
import { deletetApi, getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, notifyError, notifySuccess,Loader } from 'src/utils/constants';
// import { DashboardLayout } from 'src/views/DashboardLayout';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const customStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '55%'
  },
};



const RestaurantList = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalBodyDelete, setModalBodyDelete] = useState(false);
  const [modalBodyUpdate, setModalBodyUpdate] = useState(false);
  const [restaurant, setRestaurant] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setloading] = useState(false)  
  const [spinner ,setSpinner] =useState(false)

  const [inputField, setInputField] = useState({
    phoneNumber: '',
    restaurantId: '',
    city: '',
    state: '',
    zip: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    restaurantName:''
  })

 
const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name:inputField.restaurantName,
      city:inputField.city,
      phoneNumber: inputField.phoneNumber,
      state: inputField.state,
      zip: inputField.zip,
      addressLine1: inputField.addressLine1,
      addressLine2: inputField.addressLine2,
    }
    setloading(true)
    try {
      const res = await putApi(`${API_PATH.SUPER_ADMIN.RESTAURANT}/${inputField.restaurantId}`, data);
      if (res.status == 200) {
        setloading(false)
        setIsOpen(false);
        notifySuccess("Update Successfully!")
        // setRes(res.data.data);
      }
    } catch (error) {
      console.log(error)
      setloading(false)
      notifyError("Error!")
    }
  }

  const onDelete = async(e)=>{
    e.preventDefault();
    setloading(true)
    try {
      const res = await deletetApi(`${API_PATH.SUPER_ADMIN.RESTAURANT}/${inputField.restaurantId}`);
      if (res.status == 200) {
        setloading(false)
        setIsOpen(false);
        // setRes(res.data.data);
      }
    } catch (error) {
      console.log(error)
      setloading(false)
    }
  }


  const filteredItems = restaurant.filter(
    item => item.restaurantName && item.restaurantName.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  function openModal(check,row) {
    if (check == 1) {
      setModalBodyUpdate(true)
      setModalBodyDelete(false)
      setInputField(row)
    } else {
      setModalBodyDelete(true)
      setModalBodyUpdate(false)
      setInputField(row)
    }
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }





  useEffect(() => {
    getRestaurant()
  }, [loading==false])

  async function getRestaurant() {
    try {
      setSpinner(true)
      const res = await getApi(API_PATH.SUPER_ADMIN.RESTAURANT);
      if (res.status == 200) {
        setRestaurant(res.data.data.restaurants);
        setSpinner(false)
      }
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }


  const columns = [
    
    {
      name: '#',
      cell: (row, index) => index + 1  //RDT provides index by default
    },
    {
      name: "Restaurant Name",
      selector: (row) => row.restaurantName,
      sortable: true
    },
    {
      name: "Updted At",
      selector: (row) => getDateTime(row.createdAt),
      sortable: true
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <a onClick={() => openModal(1,row)}><CIcon size={'lg'}  icon={cilPen} className="cil-energy icon text-primary m-1"></CIcon></a>

          <a onClick={() => openModal(0,row)}><CIcon size={'lg'} icon={cilTrash} className="cil-energy icon text-danger m-1"></CIcon></a>
          {/* <i class="cib-twitter icon icon-xxl"></i> */}
        </>
      ),
      sortable: true
    },
  ];


  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>


            <CCol xs={12}>
              <CCard className="mb-4">

                <CCardBody>
                <h3>Restaurants</h3>

                  <DataTable
                    className='rest'
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    pagination={5}
                    paginationRowsPerPageOptions={[100,200,300,400]}
                    paginationPerPage={100}
                    highlightOnHover
                    subHeaderComponent={subHeaderComponentMemo}
                  />

                </CCardBody>
              </CCard>
            </CCol>
            <ReactModal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalBodyUpdate ? customStyles2 : customStyles}
              contentLabel="Example Modal"

            >
              <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

              {modalBodyUpdate && <div>
                <CCardHeader>
                  <strong>Update Restaurant</strong>
                </CCardHeader>
                <CCardBody>
                  <CForm className="row g-3" onSubmit={onSubmit}>
                    <CCol md={12}>
                      <CFormInput type="text" id="inputEmail4" label="Restaurant Name" required name='restaurantName' onChange={inputsHandler} value={inputField.restaurantName} />
                      <CFormInput type="text" id="inputEmail4" label="phoneNumber" required name='phoneNumber' onChange={inputsHandler} value={inputField.phoneNumber} />
                      <CFormInput type="text" id="inputEmail4" label="zip" required name='zip' onChange={inputsHandler} value={inputField.zip}  />
                      <CFormInput type="text" id="inputEmail4" label="City"  required name='city' onChange={inputsHandler} value={inputField.city} />
                      <CFormInput type="text" id="inputEmail4" label="State" required name='state' onChange={inputsHandler} value={inputField.state} />
                      <CFormInput type="text" id="inputEmail4" label="Address1" required name ='addressLine1' onChange={inputsHandler} value={inputField.addressLine1} />
                      <CFormInput type="text" id="inputEmail4" label="Address2" required name ='addressLine2' onChange={inputsHandler} value={inputField.addressLine2} />
                    </CCol>



                    <div className="d-grid gap-2">
                      <CButton type="submit">Update</CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </div>
              }
              {modalBodyDelete && <div>
                <CCardHeader>
                  <h3>Action</h3>
                  <p>Are you sure you want to delete your account?</p>


                </CCardHeader>
                <CCardBody>
                  <div className="clearfix">
                    <button type="button" onClick={closeModal} className="btn btn-secondary m-3" >Close</button>
                    <button type="button" onClick={onDelete} className="btn btn-danger">Delete</button>
                  </div>

                </CCardBody>
              </div>
              }
            </ReactModal>
            {spinner==true && <Loader/>}
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>


  )
}

export default RestaurantList
