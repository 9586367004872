import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormSelect,
  CButton,
} from '@coreui/react'
import { API_PATH, notifyError, notifySuccess } from 'src/utils/constants'
import { getApi, postApi } from 'src/api/Api'
// import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import AppSidebar from 'src/components/AppSidebar'



 function AddProducts() {

  const [category,setCategory] =useState([])
  const [unitType,setUnitType] =useState([])

  const [inputField , setInputField] = useState({
    "itemName": "",
    "categoryId": "",
    "description": "",
    "unitType": ""
})

const inputsHandler = (e) =>{
    const { name, value } = e.target;
   setInputField((prevState) => ({
     ...prevState,
     [name]: value,
   }));
}
const onSubmit = async(e)=>{
  e.preventDefault();
  if(inputField.categoryId==""){
    return notifyError("Please select category")
  }
  if(inputField.unitType==""){
    return notifyError("Please select unit type")
  }

  try {
    const res = await postApi(API_PATH.SUPER_ADMIN.ITEM,inputField);
    if(res.status==200){
      setInputField({});
      notifySuccess("Item Added Successfully")
      setInputField({
        "itemName": "",
        "categoryId": "",
        "description": "",
        "unitType": ""
    })
    getCategory()
      // setRes(res.data.data);
    }
  } catch (error) {
    console.log(error)
    notifyError("Error")
  }
}

useEffect(()=>{
  getCategory()
  getUnitType()
},[])

async function getCategory() {
  try {
    const res = await getApi(API_PATH.SUPER_ADMIN.CATEGORY);
    if(res.status==200){
      if(res.data.data.category.length){
        setCategory(res.data.data.category);
      }

    }
  } catch (error) {
    console.log(error)
  }
}

async function getUnitType() {
  try {
    const res = await getApi(API_PATH.SUPER_ADMIN.UNIT_TYPE);
    if(res.status==200){
      if(res.data.data.unitType.length){
        setUnitType(res.data.data.unitType)
      }
    }
  } catch (error) {
    console.log(error)
  }
}
  return (
    <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
      <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add Item</strong>
          </CCardHeader>
          <CCardBody>
            <CForm className="row g-3" onSubmit={onSubmit}>
              <CCol md={6}>
                <CFormInput type="text" id="inputEmail4" label="Item Name" required name="itemName" value={inputField.itemName} onChange={inputsHandler} />
              </CCol>
              <CCol md={6}>
                <CFormSelect id="inputState" label="Unit Type" name="unitType" required value={inputField.unitType} onChange={inputsHandler}>
                  <option  >Choose...</option>
                  {/* <option value={"Kilogram"}>Kilogram</option>
                  <option value={"Litre"}>Litre</option> */}
                  {unitType && unitType.map((data,index)=>{
                    return (
                      <option key={index} value={data}>{data}</option>
                    )
                  })}
                </CFormSelect>
              </CCol>
              <CCol md={6}>
                <CFormSelect id="inputState" label="Assign Category" name="categoryId" required value={inputField.categoryId} onChange={inputsHandler} >
                  <option>Choose...</option>
                  {category && category.map((data,index)=>{
                    return (
                      <option key={index} value={data.categoryId}>{data.categoryName}</option>
                    )
                  })}
                </CFormSelect>
              </CCol>
              <CCol xs={12}>
                <CFormInput id="inputAddress" label="Description" name="description" value={inputField.description} onChange={inputsHandler} />
              </CCol>
         
              <CCol xs={12}>
                <CButton type="submit">Add Item</CButton>
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </div>
      {/* <AppFooter /> */}
    </div>
  </div>

  )
}

export default AddProducts
