

import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow, CButton } from '@coreui/react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, Loader, makeDate } from 'src/utils/constants';

let array=[]
export const ItemHistory = () => {
  const [selelctRestaurant, setselelctRestaurant] = useState(null);
  const [restaurantOption, setrestaurantOption] = useState([]);
  const [selectItem, setselectItem] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [spinner ,setSpinner] =useState(false)



  const columns = [
    {
      name: <p>#</p>,
      selector: (row,index) => index+1 
    },
    {
      name: "Item",
      selector: (row) => row.itemName,
      sortable: true
    },
    {
      name: "Category",
      selector: (row) => row.categoryName,
      sortable: true
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true
    },
    {
      name: "Unit Type",
      selector: (row) => row.unitType,
      sortable: true
    },
    {
      name: "Admin",
      selector: (row) => row?.adminName ,
      sortable: true
    },
    {
      name: "Restaurant",
      selector: (row) => row.restaurantName,
      sortable: true
    },


    {
      name: "Order At",
      selector: (row) => row.createdAt,
      sortable: true
    },
     
  ];


  useEffect(()=>{
    getRestaurants();
   
  },[])

  async function getRestaurants() {
      try {
        setSpinner(true)
        const res = await getApi(`${API_PATH.SUPER_ADMIN.RESTAURANT}`)
        if(res.status==200 && res.data.data.restaurants.length){
            const data = res.data.data.restaurants

            for (let index = 0; index < data.length; index++) {
                data[index].value = data[index].restaurantId;
                data[index].label = data[index].restaurantName;
              }
          setrestaurantOption(data)
          getItems()
        }
      } catch (error) {
        setrestaurantOption([])
        setSpinner(false)
      }
  }
  async function getItems() {
      try {
        const res = await getApi(`${API_PATH.SUPER_ADMIN.ITEM}`)
        if(res.status==200 && res.data.data.items.length){
        //   setOrders(res.data.data.orders)
        const data = res.data.data.items
        for (let index = 0; index < data.length; index++) {
            data[index].value = data[index].itemId;
            data[index].label = data[index].itemName;
          }
      setItemOptions(data)
       setSpinner(false)
        }
      } catch (error) {
        setItemOptions([])
        setSpinner(false)
      }
  }

  async function ApplyFilter(){
    try {
      setSpinner(true)
        const array =[];
        for (let index = 0; index < selectItem.length; index++) {
            const element = selectItem[index];
            array.push(element.itemId)
            
        }
        const res = await getApi(`${API_PATH.SUPER_ADMIN.ITEM_HISTORY}?restaurantId=${selelctRestaurant.restaurantId}&items=${array.toString()}`)
        if(res.status==200 && res.data.data.itemHistory.length){
        setItems(res.data.data.itemHistory)
       
        }
        setSpinner(false)
      } catch (error) {
        console.log(error);
        setSpinner(false)
      }
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>

            <CCol xl={12}>
              <CCard className="mb-4">

                <CCardBody>
                <h3>Item History</h3>
                <CRow>
                    <CCol xl={5} className="mt-2">
                    <Select
                  placeholder={"Select Restaurant"}
                //   isMulti={true}
                  defaultValue={selelctRestaurant}
                  onChange={setselelctRestaurant}
                  options={restaurantOption}
                  />
                  </CCol>
                  <CCol xl={5} className="mt-2">
                  <Select
                  placeholder={"Select Items"}
                  isMulti={true}
                  defaultValue={selectItem}
                  onChange={setselectItem}
                  options={itemOptions}
                  />
                  </CCol>
                  <CCol xl={2} className="mt-2">
                  <CButton onClick={ApplyFilter}>Apply Filter</CButton>
                  </CCol>
                  </CRow>
                  <DataTable
                    columns={columns}
                    data={items}
                    defaultSortFieldId
                    pagination={5}
                    paginationRowsPerPageOptions={[100,200,300,400]}
                    paginationPerPage={100}
                    highlightOnHover
                  />

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
        {spinner==true && <Loader/>}
      </div>
    </div>
  )
}


