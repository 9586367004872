import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CNavItem, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import admin_nav from '../adminnav'
import useAuth from 'src/hooks/useAuth'
import { getApi } from 'src/api/Api'
import { API_PATH } from 'src/utils/constants'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const { auth } = useAuth();
  const [restaurant, setRestaurant] = useState([]);


  async function getRestaurant() {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.RESTAURANT);
      if (res.status == 200) {
        setRestaurant(res.data.data.restaurants);
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if(localStorage.getItem("roles")==1){
      getRestaurant();
    }
   
  }, [])

  useEffect(() => {
    setRoutes()
  }, [restaurant])

  const setRoutes = () => {
    if (restaurant.length) {
      for (let index = 0; index < navigation.length; index++) {
        const element = navigation[index];
        if (element.name == "Orders") {
          element.items = [];
          element.items.push({
            component: CNavItem,
            name: 'Consolidated Orders',
            to: '/dashboard/order/consolidated',
          })
          for (let index = 0; index < restaurant.length; index++) {
            const element2 = restaurant[index];
            element.items.push({
              component: CNavItem,
              name: element2.restaurantName,
              to: `/dashboard/orders/${element2.restaurantId}`,
            })

          }
        }
      }
    }
  }
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <h5>Inventory System</h5>
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={localStorage.getItem("roles")==1 ? navigation : admin_nav} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default AppSidebar;
