import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingImg from "../assets/images/loading.gif"
import ReactModal from 'react-modal';
import { CCardBody } from '@coreui/react';
import Marquee from "react-fast-marquee";



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: "none"
  },
};

export const customStyles3 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px"
  },
};
export const customStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
    width: '55%'
  },
};
export default function getDateTime(myDate) {
  const requestTimezone = "Asia/Calcutta";
  const newDate = new Date(myDate).toLocaleString("en-CA", {
    dateStyle: "short",
    timeZone: requestTimezone,
  });
  return newDate;
}


export const API_PATH = {
  AUTH: {
    LOGIN: 'authenticate-user/login',
    GET_PROFILE: 'authenticate-user/profile',
  },
  SUPER_ADMIN: {
    USERS: 'super-admin/user',
    CATEGORY: 'super-admin/category',
    RESTAURANT: 'super-admin/restaurant',
    ITEM: 'super-admin/item',
    ORDERS: 'super-admin/orders',
    UPDATE: 'super-admin',
    UNIT_TYPE: 'super-admin/item/unit-type',
    ORDER_STATUS: 'super-admin/order-status',
    ITEM_HISTORY: 'super-admin/item-history',
    NEWS:'super-admin/orders-as-news?restaurantId=',
    TRANSFER:{
      transferItem:"super-admin/transfer/item",
      transferOrder:"super-admin/transfer/order",
      transferItemOrder:"super-admin/transfer/transfer-items",
      stockConsumed:"super-admin/transfer/stock-consumed",
      stcokOut:"super-admin/transfer/stock-out"
    }
  },
  ADMIN: {
    ORDER: 'admin/order',
    itemCategory: 'admin/item-category',
    ADMIN_USERS: 'admin/users',
    ORDER_STATUS: 'admin/order-status',
    NEWS:'admin/orders-as-news',
    TRANSFER:{
      transferItem:"admin/transfer/transfer-items",
      transferInventory:"admin/transfer/inventory",
      stockIn:"admin/transfer/stock-in",
      stcokOut:"admin/transfer/stock-out"
    }
  }
}
export const getDate = () => {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return date
}

export const makeDate = (date) => {
  const today = new Date(date);
  const format = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return format
}

export const getFullDateTime = (date) => {
  const today = new Date(date);
  const format = today.toGMTString();
  return format
}
export const getTrimDate = (date) => {
  return date.split('.')[0].replace("T"," ")
}

export const notifySuccess = (message) => {
  return (
    toast(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "success"
    })
  )
}

export const notifyError = (message) =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: "error"
  });


export const Loader = () => {
  return <ReactModal
    isOpen={true}
    style={customStyles}
  >
    <div>

      <CCardBody>
        <img src={loadingImg} height="75px" width="75px" />

      </CCardBody>
    </div>
  </ReactModal>
}
export const MyMarquee =({message})=>{
 return <Marquee speed={40} pauseOnHover={true}>
  {message.map((data,index)=>{
    return <div className='m-3 news-container'>
       <div><span className='count-title'>No. of Pending Orders -  <span className='count-span fw-bolder'>{data.orderPendingCount}</span></span></div> 
        <p className='text-center p-0 m-0 fw-bolder news-date'>{data.date}</p>
    </div>
  })}
</Marquee>
}


