import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton, CCardHeader, CFormInput, CForm, CFormSelect, CTooltip, CLink,
} from '@coreui/react'
import {
  cilTrash,ey
} from '@coreui/icons'
import DataTable from 'react-data-table-component';
import CIcon from '@coreui/icons-react';
import ReactModal from 'react-modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FilterComponent from 'src/views/FilterComponent';
import AppSidebar from 'src/components/AppSidebar';
import AppHeader from 'src/components/AppHeader';
// import AppFooter from 'src/components/AppFooter';
import getDateTime, { API_PATH, makeDate, notifyError, notifySuccess, Loader, MyMarquee } from 'src/utils/constants';
import { deletetApi, getApi, putApi } from 'src/api/Api';
import Select from 'react-select';
import useAuth from 'src/hooks/useAuth';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px"
  },
};

const customStylesTable = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

function History() {
  const [adminUsers, setAdminUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [userId, setUserId] = useState(null);
  const [value, onChange] = useState([new Date(), new Date()]);
  const [startDate, setStartDate] = useState(makeDate(new Date()))
  const [endDate, setEndDate] = useState(makeDate(new Date()))
  const [filteredItems, setfilteredItems] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [updateItems, setupdateItems] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalBodyDelete, setModalBodyDelete] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);


  useEffect(() => {
    if (filterText != "") {
      const data = orders.filter(
        item => item.itemName && item.itemName.toLowerCase().includes(filterText.toLowerCase()),
      );
      setfilteredItems(data)
    } else {
      setfilteredItems(orders)
    }

  }, [filterText])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (<>

      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Search Item"} />
    </>
    );
  }, [filterText, resetPaginationToggle]);


  function selectOrder(e, data) {
    if (e.target.checked) {
      const customdata = {
        orderId: data.orderId,
        status: "RECEIVED"
      }
      setupdateItems(old => [...old, customdata]);
    } else {
      setupdateItems(updateItems.filter((i) => (i.orderId !== data.orderId)))
    }

  }

  async function updateStatus() {
    try {
      const res = await putApi(API_PATH.ADMIN.ORDER_STATUS, updateItems)
      if (res.status == 200) {
        setupdateItems([]);
        getOrders(startDate,endDate)
      }

    } catch (error) {
      console.log(error)
    }
  }

  function  checkForNote(data){
    if(data!='undefined' && data!='null' && data!=null && data!=""){
      return true
    }else{
      return false
    }
    }
  const columns = [
    // {
    //   name: "Order ID",
    //   selector: (row) => row.orderId,
    //   sortable: true
    // },
    {
      id: "history1",
      name: "Action",

      selector: (row) => (
        <>
          <input type="checkbox" className='custom_check'
            disabled={row.orderStatus == "NEW-REQUEST" || row.orderStatus == "RECEIVED" || row.orderStatus == null || row.userId != localStorage.getItem("uId") ? true : false}
            onClick={(e) => selectOrder(e, row)} />
        </>
      ),
      compact: true
    },
    {
      id: "history2",
      name: "Order Status",
      selector: (row) => <div className={row.orderStatus == "NEW-REQUEST" ? "new_request " : row.orderStatus == "BOUGHT" ? "bought_admin pulse2" : row.orderStatus == null ? "" : "recieved"}>{row.orderStatus}</div>,
      sortable: true,
      compact: true
    },
    {
      id: "history3",
      name: "Item Name",
      style: { paddingRight: "5px" },
      selector: (row) => row.itemName,
      sortable: true,
      compact: true
    },

    {
      id: "history4",
      name: "Category",
      selector: (row) => row.categoryName,
      sortable: true,
      compact: true

    },
    {
      id: "history5",
      name: <div className='text-center'>Requested <br></br>Qty</div>,
      selector: (row) => row.quantity,
      sortable: true,
      compact: true

    },
    {
      id: "history57",
      name: <div className='text-center'>Recieved <br></br>Qty</div>,
      selector: (row) =>
      <div>
       { row.orderStatus == "BOUGHT" || row.orderStatus == "RECEIVED" ? 
            <>
            <span className='m-1'>{row.receivedQty}</span>
            { checkForNote(row.noteReceivedQty) ? <CTooltip  content={row.noteReceivedQty}>
        <i className="fa fa-eye eye"></i>
       </CTooltip> : ""} </>: ""}
    </div>,
      // <div data-tooltip-content="hello world">{row.quantity}</div>,
      sortable: true,
      compact: true

    },
    {
      id: "history6",
      name: "Username",
      selector: (row) => row.name,
      sortable: true,
      compact: true

    },
    {
      id: "history7",
      name: "Restaurant Name",
      style: { paddingRight: "5px" },
      selector: (row) => row.restaurantName,
      sortable: true,
      compact: true
    },
    {
      id: "history8",
      name: "Order At",
      selector: (row) => getDateTime(row.createdAt),
      sortable: true,
      compact: true

    },
    {
      name: "Action",
      selector: (row) => (
        <>

          {
            row.orderStatus == "NEW-REQUEST" && row.userId == localStorage.getItem("uId") && <a style={{ cursor: "pointer" }} onClick={() => openModal(row)} ><CIcon icon={cilTrash} class="cil-energy icon text-danger"></CIcon></a>}
        </>
      ),
      sortable: true
    },
  ];

  const onUserChange = (e) => {
    setUserId(e.target.value)
  }

  const getSingleUserOrder = async (startDate,endDate) => {
    try {
      setLoading(true)
      const res = await getApi(`${API_PATH.ADMIN.ORDER}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
      if (res.status == 200) {
        setOrders(res.data.data.orders)
        setfilteredItems(res.data.data.orders);
        filterItems(res.data.data.orders)
        getAdminUsers()
      }
    } catch (error) {
      setOrders([])
      setLoading(false)
    }
  }

  const getDefaultOrders = async (startDate,endDate) => {
    try {
      setLoading(true)
      // const todayDate = getDate();
      const res = await getApi(`${API_PATH.ADMIN.ORDER}?startDate=${startDate}&endDate=${endDate}`)
      if (res.status == 200) {
        setOrders(res.data.data.orders)
        setfilteredItems(res.data.data.orders);
        filterItems(res.data.data.orders)
        getAdminUsers()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getOrders = async (startDate,endDate) => {
    if (userId != null) {
      getSingleUserOrder(startDate,endDate)
    } else {
      getDefaultOrders(startDate,endDate)
    }
  }

  const getAdminUsers = async () => {
    try {
      const res = await getApi(API_PATH.ADMIN.ADMIN_USERS)
      if (res.status == 200) {
        const users = res.data.data.adminUsers;
        setAdminUsers(users)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    const sdate = makeDate(value[0]);
    const edate = makeDate(value[1]);
    setStartDate(sdate)
    setEndDate(edate)
    getOrders(sdate,edate)
    // const data = orders.filter(
    //   item => {
    //     if(!item.createdAt) {return};
    //     const date = new Date(item.createdAt)
    //     const sdate = new Date(value[0])
    //     const edate = new Date(value[1])
    //     return (date >= sdate && date <= edate);
    //   }
    // )
    // setfilteredItems(data)
  }, [value])


  useEffect(() => {
    // const startDate = getDate();
    getOrders(startDate,endDate)
  }, [orderId == "", userId])

  // useEffect(()=>{
  //   if(userId!=null){
  //     getSingleUserOrder();
  //   }
  // },[userId])

  function clearFilter() {
    setfilteredItems(orders)
  }


  useEffect(() => {
    getCategories()
    getNews()
  }, [])

  async function getCategories() {
    try {
      const res = await getApi(API_PATH.ADMIN.itemCategory)
      if (res.status == 200) {
        const data = res.data.data.category;
        for (let index = 0; index < data.length; index++) {
          data[index].value = data[index].categoryId;
          data[index].label = data[index].categoryName;
        }
        setOptions(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getNews() {
    try {
      const res = await getApi(API_PATH.ADMIN.NEWS)
      if (res.status == 200) {
        const data = res.data.data.ordersNews;
        setNews(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedOption != null && selectedOption.length) {
      const res = orders.filter(item => selectedOption.some(e => e.label == item.categoryName));
      setfilteredItems(res)
    } else if (selectedOption == null) {
    }
    else if (selectedOption.length == 0) {
      setfilteredItems(orders)
    }

  }, [selectedOption])

  const filterItems = (orders) => {
    if (selectedOption != null && selectedOption.length) {
      const res = orders.filter(item => selectedOption.some(e => e.label == item.categoryName));
      setfilteredItems(res)
    } else if (selectedOption == null) {
    }
    else if (selectedOption.length == 0) {
      setfilteredItems(orders)
    }
  }

  function openModal(row) {

    setModalBodyDelete(true)
    setIsOpen(true);
    setOrderId(row.orderId);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onDelete = async () => {
    try {
      const res = await deletetApi(`${API_PATH.ADMIN.ORDER}/${orderId}`);
      if (res.status == 200) {
        notifySuccess("Order Deleted Successfully!")
        setOrderId("");
        closeModal()
      }
    } catch (error) {
      notifyError("Something went wrong!")
    }
  }

  function handleCallback(start, end, label) {
    const array = [start,end];
    onChange(array);
  }

  function applyfunc(start, date, label) {
    const array = [date.startDate,date.endDate];
    onChange(array);
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>

            <CCol xs={12}>
              <CCard className="mb-4">

                <CCardBody>
                  <CRow>
                    <CCol xl={4} className="mb-3">
                      <CFormSelect id="inputState" label="See Other Users History" name='userId' onChange={onUserChange}>
                        <option value="disabled">Select User</option>
                        {adminUsers && adminUsers.map((data, index) => {
                          return (
                            <option key={index} value={data.userId}>{data.email == auth.username ? `${data.userName} (You)` : data.userName}</option>
                          )
                        })}
                      </CFormSelect>
                    </CCol>
                  </CRow>
                  <h3>Order History</h3>
                  {news.length>0 && <MyMarquee message={news}/>}

                  <CRow>

                    <CCol xl={6}>
                      {/* <div style={{width:"40%", marginTop:"20px"}}> */}
                      <Select required
                        placeholder={"Filter based on categories"}
                        isMulti={true}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        customStyles={customStylesTable}
                      />
                      {/* </div> */}
                    </CCol>
                    <CCol xl={6}>
                      <div className='filter_wrapper'>
                        {/* <DateRangePicker onChange={onChange} value={value} /> */}

                        <DateRangePicker  onApply={applyfunc}>
        <input className='form-control calendar-text'/>
      </DateRangePicker>

                      </div>
                    </CCol>
                    <CCol xl={6} className="mt-2">
                      {orders.length > 0 && <CButton disabled={updateItems.length == 0 ? true : false} onClick={updateStatus} >Update Status to RECEIVED</CButton>}
                    </CCol>
                  </CRow>

                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    pagination={5}
                    highlightOnHover
                    subHeader
                    paginationRowsPerPageOptions={[100, 200, 300, 400]}
                    paginationPerPage={100}
                    subHeaderComponent={subHeaderComponentMemo}
                  />

                </CCardBody>
              </CCard>
            </CCol>

          </CRow>
        </div>
        {/* <AppFooter /> */}
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Delete"

      >
        <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {modalBodyDelete && <div>
          <CCardHeader>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this order ?</p>


          </CCardHeader>
          <CCardBody>
            <div clasName="clearfix" style={{ textAlign: "end" }}>
              <CButton color='primary' type="button" onClick={closeModal} className="m-2" >Close</CButton>
              <CButton color="danger" type="button" onClick={onDelete} className="ml-3">Delete</CButton>
            </div>
          </CCardBody>
        </div>
        }
      </ReactModal>
      {loading == true && <Loader status={loading} />}
    </div>

  )
}
export default History
