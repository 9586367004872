import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CForm,
    CFormInput,
    CFormSelect,
    CButton,
    CSpinner,
} from '@coreui/react'
import { API_PATH, Loader, customStyles2, customStyles3, notifyError, notifySuccess } from 'src/utils/constants'
import { deletetApi, getApi, postApi, putApi } from 'src/api/Api'
// import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import AppSidebar from 'src/components/AppSidebar'
import DataTable from 'react-data-table-component'
import ReactModal from 'react-modal'
import CIcon from '@coreui/icons-react'
import { cilPen, cilTrash } from '@coreui/icons'

const mydata = [
    {
        "itemName": "Sushi",
        "unitType": "Kilogram"
    },
    {
        "itemName": "Pasta",
        "unitType": "Liter"
    },
    {
        "itemName": "Sushi",
        "unitType": "Box"
    },
    {
        "itemName": "Pasta",
        "unitType": "Cans"
    },
    {
        "itemName": "Sushi",
        "unitType": "Bag"
    },
    {
        "itemName": "Pasta",
        "unitType": "Pack"
    }
]


export const AddItem = () => {
    const [unitType, setUnitType] = useState([])

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalBodyDelete, setModalBodyDelete] = useState(false);
    const [modalBodyUpdate, setModalBodyUpdate] = useState(false);
    const [items, setItems] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])

    const [inputField, setInputField] = useState({
        "itemName": "",
        "unitType": ""
    })


    const columns = [

        {
            id: "item1",
            name: '#',
            selector: (row, index) => index + 1  //RDT provides index by default
        },
        {
            id: "item2",
            name: "Item Name",
            selector: (row) => row.itemName,
            sortable: true
        },
        {
            id: "item4",
            name: "UnitType",
            selector: (row) => row.unitType,
            sortable: true
        },
        {
            name: "Action",
            sortable: true,
            selector: (row) => (
                <>
                    <a onClick={() => openModal(1, row)}><CIcon size={'lg'} icon={cilPen} className="cil-energy icon text-primary m-1"></CIcon></a>

                    <a onClick={() => openModal(0, row)}><CIcon size={'lg'} icon={cilTrash} className="cil-energy icon text-danger m-1"></CIcon></a>
                    {/* <i class="cib-twitter icon icon-xxl"></i> */}
                </>
            ),
            sortable: true
        },
    ];

    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const onSubmit = async (e,clickFrom) => {
        e.preventDefault();
        if(clickFrom==1){
            if (inputField.itemName == "") {
                return notifyError("please add item name")
            }
            if (inputField.unitType == "") {
                return notifyError("Please select unit type")
            }
    
            try {
                  const res = await postApi(API_PATH.SUPER_ADMIN.TRANSFER.transferItem,inputField);
                  if(res.status==200){
                    setInputField({});
                    notifySuccess("Item Added Successfully")
                    setInputField({
                      "itemName": "",
                      "unitType": ""
                  })
                  getUnitType()
                  getItems()
                  }
            } catch (error) {
                console.log(error)
                notifyError("Error")
            }
        }else{
            try {
                  const res = await putApi(API_PATH.SUPER_ADMIN.TRANSFER.transferItem+"/"+inputField.itemId,{itemName: inputField.itemName,unitType:inputField.unitType});
                  if(res.status==200){
                    setInputField({});
                    closeModal()
                    notifySuccess("Item Updated Successfully")
                    setInputField({
                      "itemName": "",
                      "unitType": ""
                  })
                  getUnitType()
                  getItems()
                  }
            } catch (error) {
                console.log(error)
                notifyError("Error")
            }
        }
      
    }

    function openModal(check, row) {
        if (check == 1) {
            setModalBodyUpdate(true)
            setModalBodyDelete(false)
            setInputField(row)
        } else {
            setModalBodyDelete(true)
            setModalBodyUpdate(false)
            setInputField(row)
        }
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        getUnitType()
        
    }, [])


    async function getUnitType() {
        try {
            setSpinner(true)
            const res = await getApi(API_PATH.SUPER_ADMIN.UNIT_TYPE);
            if (res.status == 200) {
                if (res.data.data.unitType.length) {
                    setUnitType(res.data.data.unitType)
                    getItems()
                }
            }else{
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            console.log(error)
        }
    }

    async function getItems() {
        try {
            
            const res = await getApi(API_PATH.SUPER_ADMIN.TRANSFER.transferItem);
            if (res.status == 200) {
                if (res.data.data.transferItems.length) {
                    // setUnitType(res.data.data.unitType)
                    setData(res.data.data.transferItems)
                    setSpinner(false)
                }else{
                    setSpinner(false)
                }
            }else{
                setSpinner(false)
            }
        } catch (error) {
            console.log(error)
            setSpinner(false)
        }
    }


    const onDelete = async (e) => {
        e.preventDefault();
        setloading(true)
        try {
            const res = await deletetApi(`${API_PATH.SUPER_ADMIN.TRANSFER.transferItem}/${inputField.itemId}`);
            if (res.status == 200) {
                setloading(false)
                setIsOpen(false);
                notifySuccess("Delete Successfully")
                setInputField({});
                closeModal()
                setInputField({
                  "itemName": "",
                  "unitType": ""
              })
              getUnitType()
              getItems()
                // setRes(res.data.data);
            }
        } catch (error) {
            console.log(error)
            setloading(false)
            notifyError("Error")
        }
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <CRow>
                        <CCol xs={12}>
                            <CCard className="mb-4">
                                <CCardHeader>
                                    <strong>Add Item</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="row g-3" onSubmit={(e)=>onSubmit(e,1)}>
                                        <CCol md={6}>
                                            <CFormInput type="text" id="inputEmail4" label="Item Name" required name="itemName" value={inputField.itemName} onChange={inputsHandler} />
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormSelect id="inputState" label="Unit Type" name="unitType" required value={inputField.unitType} onChange={inputsHandler}>
                                                <option  >Choose...</option>
                                                {/* <option value={"Kilogram"}>Kilogram</option>
                    <option value={"Litre"}>Litre</option> */}
                                                {unitType && unitType.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data}>{data}</option>
                                                    )
                                                })}
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs={12}>
                                            <CButton type="submit">Add Item</CButton>
                                        </CCol>
                                    </CForm>
                                </CCardBody>

                            </CCard>
                        </CCol>
                        <CCol>
                            <CCard>
                                <CCardHeader>
                                    <strong>Items</strong>
                                </CCardHeader>
                                <CCardBody id='add_item_transfer'>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        defaultSortFieldId
                                        pagination={5}
                                        highlightOnHover
                                        subHeader
                                        paginationRowsPerPageOptions={[100, 200, 300, 400]}
                                        paginationPerPage={100}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>

                        <ReactModal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={modalBodyUpdate ? customStyles2 : customStyles3}
                            contentLabel="Example Modal"

                        >
                            <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

                            {modalBodyUpdate && <div>
                                <CCardHeader>
                                    <strong>Update Item</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="row g-3" onSubmit={(e)=>onSubmit(e,2)}>
                                        <CCol md={12}>
                                            <CFormInput type="text" id="inputEmail4" label="Item Name" name='itemName' required onChange={inputsHandler} value={inputField.itemName} />
                                            <CFormSelect id="inputState" label="unitType" name='Unit Type' required onChange={inputsHandler} value={inputField.unitType}>
                                                {/* <option  >Choose...</option> */}
                                                {/* <option value={"Kilogram"}>Kilogram</option>
                  <option value={"Litre"}>Litre</option> */}
                                                {unitType && unitType.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data}>{data}</option>
                                                    )
                                                })}
                                            </CFormSelect>
                                        </CCol>



                                        <div className="d-grid gap-2">
                                            {loading ? <CButton disabled>
                                                <CSpinner component="span" size="sm" aria-hidden="true" />
                                                Loading...
                                            </CButton> : <CButton type="submit">Update</CButton>}
                                        </div>
                                    </CForm>
                                </CCardBody>
                            </div>
                            }
                            {/* {modalBodyDelete && <div>
                                <CCardHeader>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete your account?</p>


                                </CCardHeader>
                                <CCardBody>
                                    <div className="clearfix">
                                        <button type="button" onClick={closeModal} className="btn btn-secondary m-3" >Close</button>
                                        <button type="button" onClick={onDelete} className="btn btn-danger">Delete</button>
                                    </div>

                                </CCardBody>
                            </div>
                            } */}

{modalBodyDelete && <div>
                <CCardHeader>
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete this Item ?</p>


                </CCardHeader>
                <CCardBody>
                  <div clasName="clearfix" style={{textAlign:"end"}}>
                    <CButton color='primary' type="button" onClick={closeModal} className="m-2" >Close</CButton>
                    <CButton color="danger"  type="button" onClick={onDelete} className="ml-3">Delete</CButton>
                  </div>
                </CCardBody>
              </div>}
                        </ReactModal>
                    </CRow>
                </div>
                {/* <AppFooter /> */}
                {spinner==true && <Loader/>}
            </div>
        </div>

    )
}
