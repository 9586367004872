import React, { Component, Suspense } from 'react'
import ReactModal from 'react-modal'
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import History from './admin/History/History'
import Order from './admin/Order/Order'
import RequireAuth from './RequireAuth'
import './scss/style.scss'
import AddUsers from './views/base/addusers/AddUsers'
import AddProducts from './views/base/products/AddProducts'
import ProductList from './views/base/products/ProductList'
import UsersList from './views/base/userslist/UsersList'
import AddCategory from './views/categories/addcategories/AddCategory'
import CategoriesList from './views/categories/categorieslist/CategoriesList'
import Consolidated from './views/orders/Consolidated'
import Orders from './views/orders/Orders'
// import Dashboard from './views/dashboard/Dashboard'
import AddRestaurant from './views/restaurant/addrestaurant/AddRestaurant'
import RestaurantList from './views/restaurant/restaurantlist/RestaurantList'
// import Dashboard2 from './admin/Dashboard/Dashboard'
import Profile from './views/Profile'
import { ToastContainer, toast } from 'react-toastify';
import useAuth from './hooks/useAuth'
import { ItemHistory } from './views/Items-history/ItemHistory'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'bootstrap-daterangepicker/daterangepicker.css'; 
import { AddItem } from './views/items-transfer/AddItem'
import { TransferRequest } from './views/items-transfer/TransferRequest'
import { TransferTrack } from './views/items-transfer/TransferTrack'
import { Transfer } from './admin/Transfer/Transfer'
import { TransferTrackPage } from './common/TransferTrack'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ROLES = {
  'Superadmin': 1,
  'Admin': 2,
}
ReactModal.setAppElement('#root');
const App =  ()=> {
  const { auth,setAuth } = useAuth();
    return (
      <>
        <ToastContainer/>
        <Suspense fallback={loading}>
          
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />

          <Route
                exact
                path="/"
                element={
                      auth?.roles[0] ===1 
                      ?
                      <Navigate replace to="/dashboard" /> :
                      auth?.roles[0]===2
                      ?
                      <Navigate replcae to ="/admin"/>
                      :
                      <Navigate replace to="/login" /> 
                    }
              />

          {/* SUper Admin */}
          <Route element={<RequireAuth allowedRoles={ROLES.Superadmin} />}>
            <Route path='/dashboard' element={<UsersList />}/>
            <Route path='/dashboard/user/addusers' element={<AddUsers />}/>
            <Route path='/dashboard/user/users' element={<UsersList />}/>
            <Route path='/dashboard/product/addproduct' element={<AddProducts />}/>
            <Route path='/dashboard/product/products' element={<ProductList />}/>
            <Route path='/dashboard/category/addcategory' element={<AddCategory />}/>
            <Route path='/dashboard/category/categories' element={<CategoriesList />}/>
            <Route path='/dashboard/order/consolidated' element={<Consolidated />}/>
            <Route path='/dashboard/orders/:name' element={<Orders />}/>
            <Route path='/dashboard/restaurant/addrestaurant' element={<AddRestaurant />}/>
            <Route path='/dashboard/restaurant/restaurants' element={<RestaurantList />}/>
            <Route path='/dashboard/profile' element={<Profile />}/>
            <Route path='/dashboard/itemhistory' element={<ItemHistory />}/>

            <Route path='/dashboard/transfer/addItems' element={<AddItem />}/>
            <Route path='/dashboard/transfer/request' element={<TransferRequest />}/>
            <Route path='/dashboard/transfer/track' element={<TransferTrack />}/>
            <Route path='/dashboard/transfer/track-quantity' element={<TransferTrackPage />}/>
            </Route>
          {/*  */}

          {/* Admin */}
          <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
            <Route path='/admin' element={<Order />}/>
            <Route path='/admin/order' element={<Order />}/>
            <Route path='/admin/history' element={<History />}/>
            <Route path='/admin/profile' element={<Profile />}/>
            <Route path='/admin/transfer' element={<Transfer />}/>
            <Route path='/admin/transfer/track' element={<TransferTrackPage />}/>
          </Route>
        </Routes>
        </Suspense>
      </>
    )
}

export default App
