import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react'
import { DocsExample } from 'src/components'
import { API_PATH, notifyError, notifySuccess } from 'src/utils/constants'
import { postApi } from 'src/api/Api'
// import { DashboardLayout } from 'src/views/DashboardLayout'
import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import AppSidebar from 'src/components/AppSidebar'


const initialData ={
  "name" : "",
  "phoneNumber": "",
  "city": "",
  "state": "",
  "zip": "",
  "country": "",
  "addressLine1": "",
  "addressLine2": ""
}
const AddRestaurant = () => {
  const [inputField , setInputField] = useState(initialData)

const inputsHandler = (e) =>{
    const { name, value } = e.target;
   setInputField((prevState) => ({
     ...prevState,
     [name]: value,
   }));
}
const onSubmit = async(e)=>{
  e.preventDefault();
  try {
    const res = await postApi(API_PATH.SUPER_ADMIN.RESTAURANT,inputField);
    if(res.status==200){
      notifySuccess("Restaurant Added Successfully")
      setInputField(initialData)
      // setRes(res.data.data);
    }
  } catch (error) {
    console.log(error)
    notifyError("Error")
  }
}

  return (
    <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
      <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add Restaurant</strong>
          </CCardHeader>
          <CCardBody>
            <CForm className="row g-3" onSubmit={onSubmit}>
              <CCol md={6}>
                <CFormInput type="text" id="inputEmail4" required label="Restaurant Name" name="name" value={inputField.name} onChange={inputsHandler} />
              </CCol>
              <CCol md={6}>
                <CFormInput type="text" id="inputEmail4" required label="PHONE NUMBER" name="phoneNumber" value={inputField.phoneNumber} onChange={inputsHandler} />
              </CCol>
              <CCol md={6}>
                <CFormInput id="inputCity" label="City" required name="city" value={inputField.city} onChange={inputsHandler}  />
              </CCol>
              <CCol md={4}>
                <CFormInput id="inputState" label="Province" required name="state" value={inputField.state} onChange={inputsHandler}/>
              
              </CCol>
              <CCol md={2}>
                <CFormInput id="inputZip" label="Postal code" required  name="zip" value={inputField.zip} onChange={inputsHandler} />
              </CCol>
              <CCol md={6}>
                <CFormInput type="text" id="inputPassword4" required label="Country"  name="country" value={inputField.country} onChange={inputsHandler} />
              </CCol>
              <CCol xs={12}>
                <CFormInput id="inputAddress" label="Address" required placeholder="1234 Main St"  name="addressLine1" value={inputField.addressLine1} onChange={inputsHandler} />
              </CCol>
              <CCol xs={12}>
                <CButton type="submit">Add Restaurant</CButton>
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </div>
      <AppFooter />
    </div>
  </div>
  
  )
}

export default AddRestaurant
