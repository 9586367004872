import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormSelect,
  CButton,
  CToast,
} from '@coreui/react'
import { getApi, postApi } from 'src/api/Api'
import { API_PATH, notifyError, notifySuccess, showToast } from 'src/utils/constants'
// import { DashboardLayout } from 'src/views/DashboardLayout'
import AppSidebar from 'src/components/AppSidebar'
import AppHeader from 'src/components/AppHeader'
// import AppFooter from 'src/components/AppFooter'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'

const AddUsers = () => {
  const [rest,setRes] =useState([])

  const [inputField , setInputField] = useState({
    email: '',
    password: '',
    assignedRestaurantId: '',
    city:'',
    state:'',
    zip:'',
    addressLine1:'',
    addressLine2:'',
    name:''
})

const inputsHandler = (e) =>{
    const { name, value } = e.target;
   setInputField((prevState) => ({
     ...prevState,
     [name]: value,
   }));
}
const onSubmit = async(e)=>{
  e.preventDefault();
  if(inputField.assignedRestaurantId==""){
    return notifyError("Please Select Restaurant")
  }
  try {
    const res = await postApi(API_PATH.SUPER_ADMIN.USERS,inputField);
    if(res.status==200){
      notifySuccess("User Added Successfully")
      setInputField({
        email: '',
        password: '',
        assignedRestaurantId: '',
        city:'',
        state:'',
        zip:'',
        addressLine1:'',
        name:''
    })
    getRestaurant();
    }
  } catch (error) {
    console.log(error)
    notifyError("Error")
  }
}


useEffect(()=>{
  getRestaurant()
},[])

async function getRestaurant() {
  try {
    const res = await getApi(API_PATH.SUPER_ADMIN.RESTAURANT);
    if(res.status==200){
      if(res.data.data.restaurants.length){
        setRes(res.data.data.restaurants);
      }

    }
  } catch (error) {
    console.log(error)
  }
}

  return (
    <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
      <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add Users</strong>
          </CCardHeader>
          <CCardBody>
        
            <CForm className="row g-3" onSubmit={onSubmit}>
           
              <CCol md={6}>
                <CFormInput type="text" id="inputEmail4" label="Name"  required    name="name" 
        onChange={inputsHandler} 
        placeholder="name" 
        value={inputField.name} />
              </CCol>
              <CCol md={6}>
                <CFormInput type="email" id="inputEmail4" label="Email"  required      name="email" 
        onChange={inputsHandler} 
        placeholder="email" 
        value={inputField.email} />
              </CCol>
              <CCol md={6}>
                <CFormInput type="password" id="inputPassword4" label="Password" required name='password' onChange={inputsHandler} value={inputField.password} />
              </CCol>
              <CCol md={6}>
                <CFormSelect id="inputState" label="Assign Restaurant" name='assignedRestaurantId' required onChange={inputsHandler} value={inputField.assignedRestaurantId}>
                <option >Choose...</option>
                  {rest && rest.map((data,index)=>{
                    return (
                      <option key={index} value={data.restaurantId}>{data.restaurantName}</option>
                    )
                  })}
                </CFormSelect>
              </CCol>
              <CCol md={6}>
                <CFormInput id="inputCity" label="City" name='city' required onChange={inputsHandler} value={inputField.city} />
              </CCol>
              <CCol md={6}>
              <CFormInput id="inputCity" label="Province" name='state' required onChange={inputsHandler} value={inputField.state} />
              </CCol>
              <CCol md={6}>
                <CFormInput id="inputZip" label="Postal code" name='zip' required onChange={inputsHandler} value={inputField.zip} />
              </CCol>
              <CCol xs={12}>
                <CFormInput id="inputAddress" label="Address" required placeholder="1234 Main St" name='addressLine1' onChange={inputsHandler} value={inputField.addressLine1} />
              </CCol>
              <CCol xs={12}>
                <CButton type="submit">Add</CButton>
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </div>
      {/* <AppFooter /> */}
    </div>
  </div>    
  )
}

export default AddUsers
