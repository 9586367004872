import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormSelect,
  CButton,
  CSpinner,
} from '@coreui/react'
import Select from 'react-select';
import AppSidebar from 'src/components/AppSidebar';
import AppHeader from 'src/components/AppHeader';
// import AppFooter from 'src/components/AppFooter';
import { getApi, postApi, putApi } from 'src/api/Api';
import { API_PATH, notifySuccess } from 'src/utils/constants';
import useAuth from 'src/hooks/useAuth';

// let options = [
//   // { value: 'chocolate', label: 'Chocolate' },
//   // { value: 'strawberry', label: 'Strawberry' },
//   // { value: 'vanilla', label: 'Vanilla' },
// ];



 function Profile() {
    const {auth,setAuth} =useAuth();
const [loading,setloading]= useState(false)
  const [inputField , setInputField] = useState({
    "name": "",
    "email": "",
    "type": "",
    "password":""
})

const inputsHandler = (e) =>{
    const { name, value } = e.target;
   setInputField((prevState) => ({
     ...prevState,
     [name]: value,
   }));
}

const onSubmit = async(e)=>{
  
  e.preventDefault();
  const data = {
    password: inputField.password
}
  setloading(true)
    try {
      const res = await putApi(API_PATH.SUPER_ADMIN.UPDATE,data);
      if(res.status==200){
        setloading(false)
        notifySuccess("Password Updated Successfully");
        // setRes(res.data.data);
      }
    } catch (error) {
        setloading(false);
        notifySuccess("Something went wrong");
    }
}

async function getProfile() {
    try {
        const res =await getApi(API_PATH.AUTH.GET_PROFILE);
        if(res.status==200){
            setInputField(res.data.data[0])
        }
    } catch (error) {
        console.log(error);
    }
}

useEffect(()=>{
  getProfile()
},[])


  return (

    <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
      <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>User Profile</strong>
          </CCardHeader>
          <CCardBody>
            <CForm className="row g-3" onSubmit={onSubmit}>
            <CCol md={12}>
                      <CFormInput type="text" id="inputEmail4" label="Username" disabled name='name'  value={inputField.name} />
                      <CFormInput type="text" id="inputEmail4" label="Email" disabled name='email'  value={inputField.email} />
                  {auth && auth?.roles[0]===1 &&  <CFormInput type="text" id="inputEmail4" label="Password"  name='password' required onChange={inputsHandler} value={inputField.password} />}    
                    </CCol>
                   { auth && auth?.roles[0]=== 1 && <div className="d-grid gap-2">
                      {loading ? <CButton disabled>
                        <CSpinner component="span" size="sm" aria-hidden="true" />
                        Loading...
                      </CButton> : <CButton type="submit">Update</CButton>}

                    </div>} 
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </div>
      {/* <AppFooter /> */}
    </div>
  </div>
  
  )
}

export default Profile
