import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow, CButton, CFormInput, CFormText, CCardHeader, CFormLabel } from '@coreui/react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, customStyles2, getTrimDate, Loader, makeDate } from 'src/utils/constants';
import CIcon from '@coreui/icons-react';
import { cilReload, cilTrash } from '@coreui/icons';
import ReactModal from 'react-modal';


const data = [
    {
        "itemId": "1",
        "itemName": "Item1",
        "actual_qty": "100",
        "remaining_qty": "30"
    },
    {
        "itemId": "1",
        "itemName": "Item2",
        "actual_qty": "100",
        "remaining_qty": "10"
    },
    {
        "itemId": "1",
        "itemName": "Item3",
        "actual_qty": "100",
        "remaining_qty": "20"
    },
    {
        "itemId": "1",
        "itemName": "Item4",
        "actual_qty": "100",
        "remaining_qty": "40"
    },
    {
        "itemId": "1",
        "itemName": "Item5",
        "actual_qty": "100",
        "remaining_qty": "70"
    }
]
const data2 = [
    {
        "date": "21/3/2023 04:20 PM",
        "placed": "100",
    },
    {
        "date": "22/3/2023 07:20 PM",
        "placed": "50",
    },
    {
        "date": "23/3/2023 09:20 PM",
        "placed": "60",
    },
    {
        "date": "24/3/2023 07:20 PM",
        "placed": "70",
    },
    {
        "date": "25/3/2023 01:20 PM",
        "placed": "63",
    },
    {
        "date": "21/3/2023 04:20 PM",
        "placed": "100",
    },
    {
        "date": "22/3/2023 07:20 PM",
        "placed": "50",
    },
    {
        "date": "23/3/2023 09:20 PM",
        "placed": "60",
    },
    {
        "date": "24/3/2023 07:20 PM",
        "placed": "70",
    },
    {
        "date": "25/3/2023 01:20 PM",
        "placed": "63",
    }
]

export const TransferTrack = () => {
    const [selelctRestaurant, setselelctRestaurant] = useState(null);
    // const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [date, setDate] = useState('2023-04-04');
    const [restaurantOption, setrestaurantOption] = useState([]);
    const [selectItem, setselectItem] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [transaction, setTransaction] = useState(data2);
    const [spinner, setSpinner] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData,setModalData]=useState([])
    const [label,setLabel]=useState("Select Restaurant");
    const navigate =useNavigate()
    const { state } = useLocation();
    let params=state;



    const columns = [
        {
            name: <p>#</p>,
            selector: (row, index) => index + 1
        },
        {
            name: "Item",
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: "Total Stock Out",
            selector: (row) => row.totalStockOut,
            sortable: true
        },
        {

            name: "Remaining In Stock",
            selector: (row) => <div>
                {/* <CIcon size='lg' style={{marginLeft:"8px"}}  */}
                <span>{row.remainingInStock}</span>
                <i className="fa fa-eye eye"
                    onClick={() => openModal(row)}
                ></i>
            </div>,
            sortable: true
        },
    ];

    const columnsForTransction = [
        {
            name: <p>#</p>,
            selector: (row, index) => index + 1
        },
        {
            name: "Consumer Name",
            selector: (row, index) => row.userName
        },
        {
            name: "Date",
            selector: (row) => getTrimDate(row.orderDateTime.split('.')[0]),
        },
        {
            name: "Item Placed",
            selector: (row) => row.trackQuantity,
        },
    ];

    function openModal(row) {
        // setModalBodyDelete(true)

        // setIsOpen(true);
        // setModalData(row)
        navigate('/dashboard/transfer/track-quantity',{ state: { from: 'Super',itemId: row.itemId,rId:selelctRestaurant.restaurantId,itemData:row,rName:selelctRestaurant.restaurantName} });
        


        // setOrderId(row.orderId);
    }


    function closeModal() {
        setIsOpen(false);
    }


    useEffect(() => {
        getRestaurants();

    }, [])
    useEffect(() => {

        if(selelctRestaurant!=null){
            ApplyFilter();
        }
       

    }, [selelctRestaurant])

    async function getRestaurants() {
        try {
            setSpinner(true)
            const res = await getApi(`${API_PATH.SUPER_ADMIN.RESTAURANT}`)
            if (res.status == 200 && res.data.data.restaurants.length) {
                const data = res.data.data.restaurants
                for (let index = 0; index < data.length; index++) {
                    data[index].value = data[index].restaurantId;
                    data[index].label = data[index].restaurantName;
                }              
                setrestaurantOption(data)
                if(params!=null&& params.rId!=null){
                    const{rId,from}=params;
                    const mydata = data.find((e)=>e.restaurantId==rId);
                    setLabel(mydata.label)
                    setselelctRestaurant(mydata)
                }
                // setselelctRestaurant({value:data[0].value.toString(),label:data[0].label.toString()})
                setSpinner(false)
            }
        } catch (error) {
            setrestaurantOption([])
            setSpinner(false)
        }
    }
    // async function getItems() {
    //     try {
    //         const res = await getApi(`${API_PATH.SUPER_ADMIN.TRANSFER.transferItemOrder}`)
    //         if (res.status == 200 && res.data.data.transferItems.length) {
    //             //   setOrders(res.data.data.orders)
    //             const data = res.data.data.transferItems;
    //             itemOptions(ddata)
    //         }
    //     } catch (error) {
    //         setItemOptions([])
    //         setSpinner(false)
    //     }
    // }

    async function ApplyFilter() {
        try {
            setSpinner(true)
            const res = await getApi(`${API_PATH.SUPER_ADMIN.TRANSFER.transferItemOrder}/?restaurantId=${selelctRestaurant.restaurantId}`)
            if (res.status == 200 && res.data.data.items.length) {
                setItems(res.data.data.items)
            }
            setSpinner(false)
        } catch (error) {
            console.log(error);
            setSpinner(false)
        }
    }
    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <CRow>

                        <CCol xl={12}>
                            <CCard className="mb-4">

                                <CCardBody>
                                    <h3>Transfer Track</h3>
                                    <CRow>
                                        <CCol xl={5} className="mt-2">
                                            <Select
                                                placeholder={label}
                                                //   isMulti={true}
                                                defaultValue={selelctRestaurant}
                                                onChange={(value)=>{
                                                    setselelctRestaurant(value);
                                                    params=null;

                                                }}
                                                options={restaurantOption}
                                            />
                                        </CCol>
                                        {/* <CCol xl={5} className="mt-2">
                                            <CFormInput type="date" id="inputEmail4" value={date} onChange={(e) => setDate(e.target.value)} />
                                        </CCol> */}
                                        <CCol xl={7} className="mt-2 text-end">
                                            <CButton onClick={ApplyFilter}><CIcon icon={cilReload
                                    }></CIcon> Refresh</CButton>
                                        </CCol>
                                    </CRow>
                                    <DataTable
                                        columns={columns}
                                        data={items}
                                        defaultSortFieldId
                                        pagination={5}
                                        paginationRowsPerPageOptions={[100, 200, 300, 400]}
                                        paginationPerPage={100}
                                        highlightOnHover
                                    />

                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </div>

                <ReactModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles2}

                >
                    <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <div>
                        <CCardHeader>
                            <h4>Track</h4>
                        </CCardHeader>
                        <CCardBody>
                            <div className="mb-3">
                                <h6 class="d-inline">Item Name : </h6>
                                <div class="d-inline fw-bolder">{modalData.itemName}</div>
                            </div>
                            <div className="mb-3">
                                 <h6 class="d-inline">Actual : </h6>
                                <div class="d-inline fw-bolder">{modalData.actualQuantity}</div>
                            </div>
                            <div className="mb-3">
                            <h6 class="d-inline">Remaining : </h6>
                                <div class="d-inline fw-bolder">{modalData.actualQuantity-modalData.totalSold}</div>
                            </div>
                        </CCardBody>
                        <CCardBody className='modal_data_table'>
                        <DataTable
                                        columns={columnsForTransction}
                                        data={modalData.inventory}
                                        defaultSortFieldId
                                        pagination={5}
                                        // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                        paginationPerPage={5}
                                        highlightOnHover
                                    />
                        </CCardBody>
                    </div>

                </ReactModal>
                <AppFooter />
                {spinner == true && <Loader />}
            </div>
        </div>
    )
}
