import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow, CButton, CFormInput, CFormText, CCardHeader, CFormLabel } from '@coreui/react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, customStyles2, getTrimDate, Loader, makeDate } from 'src/utils/constants';
import CIcon from '@coreui/icons-react';
import { cilArrowCircleLeft, cilTrash } from '@coreui/icons';
import ReactModal from 'react-modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';


const data2 = [

  {
    "orderDateTime": "16/04/2023 09:20 PM",
    "trackQuantity": "60",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "10/04/2023 07:20 PM",
    "trackQuantity": "50",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "01/04/2023 04:20 PM",
    "trackQuantity": "50",
    "userName": "Ramu"
  },
]
const data3 = [
  {
    "orderDateTime": "14/04/2023 01:20 PM",
    "trackQuantity": "10",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "10/04/2023 07:20 PM",
    "trackQuantity": "25",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "09/04/2023 09:20 PM",
    "trackQuantity": "15",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "04/04/2023 07:20 PM",
    "trackQuantity": "5",
    "userName": "Ramu"
  },
  {
    "orderDateTime": "02/04/2023 04:20 PM",
    "trackQuantity": "10",
    "userName": "Ramu"
  },
]

export const TransferTrackPage = () => {
  const [selelctRestaurant, setselelctRestaurant] = useState(null);
  // const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [date, setDate] = useState('2023-04-04');
  const [restaurantOption, setrestaurantOption] = useState([]);
  const [selectItem, setselectItem] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [transaction, setTransaction] = useState(data2);
  const [spinner, setSpinner] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([])
  const [stockOut, setStockOut] = useState([])
  const [stockConsumed, setStockConsumed] = useState([])
  const [startDate, setStartDate] = useState(makeDate(new Date()))
  const [endDate, setEndDate] = useState(makeDate(new Date()))
  var mydate = new Date(), y = mydate.getFullYear(), m = mydate.getMonth();
var firstDay = new Date(y, m, 1);
var lastDay = new Date(y, m + 1, 0);
  const [value, onChange] = useState([firstDay, lastDay]);
  const [value2, onChange2] = useState([firstDay, lastDay]);
  const navigate = useNavigate()
  const { state } = useLocation();
  const { from,rName,rId,itemData } = state;
  // const columns = [
  //     {
  //         name: <p>#</p>,
  //         selector: (row, index) => index + 1
  //     },
  //     {
  //         name: "Item",
  //         selector: (row) => row.itemName,
  //         sortable: true
  //     },
  //     {
  //         name: "Actual Qty",
  //         selector: (row) => row.actualQuantity,
  //         sortable: true
  //     },
  //     {

  //         name: "Remaining Qty",
  //         selector: (row) => <div>
  //             {/* <CIcon size='lg' style={{marginLeft:"8px"}}  */}
  //             <span>{row.actualQuantity-row.totalSold}</span>
  //             <i className="fa fa-eye eye"
  //                 onClick={() => openModal(row)}
  //             ></i>
  //         </div>,
  //         sortable: true
  //     },
  // ];

  const columnsForTransction = [

    {
      name: "Date",
      sortable: true,
      selector: (row) => getTrimDate(row.date.split('.')[0]),
    },
    {
      name: "Qty",
      sortable: true,
      selector: (row) => from=="Super" ? row.stockOut : row.stockIn,
    },
  ];

  const columnsForTransction2 = [

    {
      name: "Date",
      sortable: true,
      selector: (row) => getTrimDate(row.date.split('.')[0]),
    },
    {
      name: "Consumer Name",
      sortable: true,
      selector: (row, index) => row.name
    },
    {
      name: from == "Super" ? "Consumed Qty" : "Stock Out Qty",
      sortable: true,
      selector: (row) => from =="Super" ? row.stockConsumed : row.stockOut,
    },
  ];

  function applyfunc(start, date, label) {
    const array = [date.startDate, date.endDate];
    onChange(array)
  }

  function applyfunc2(start, date, label) {
    const array = [date.startDate, date.endDate];
    onChange2(array)
  }

  

  function backToPage() {
    if (window.location.href.toString().includes('admin')) {
      navigate('/admin/transfer')
    } else {
      console.log(rId,'rId')
      navigate('/dashboard/transfer/track',{ state: { rId:rId,from:"Super"}})
    }
  }

  useEffect(()=>{
    const sdate = makeDate(value[0]);
    const edate = makeDate(value[1]);
    getStockOut(sdate,edate)
  },[from=="Super" ? value2 :value])

  useEffect(()=>{
    const sdate = makeDate(value2[0]);
    const edate = makeDate(value2[1]);
    getStockIn(sdate,edate)
  },[from=="Super" ? value :value2])

  async function getStockIn(startDate,endDate) {
    try {
      let res;
      if(from=="Super"){
        res =await getApi(`${API_PATH.SUPER_ADMIN.TRANSFER.stockConsumed}?restaurantId=${rId}&itemId=${itemData.itemId}&startDate=${startDate}&endDate=${endDate}`)
        setStockConsumed(res.data.data.stockOut)
      }else{
        res =await getApi(`${API_PATH.ADMIN.TRANSFER.stockIn}?restaurantId=${rId}&itemId=${itemData.itemId}&startDate=${startDate}&endDate=${endDate}`)
        setStockOut(res.data.data.stockIn)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getStockOut(startDate,endDate) {
    try {
      let res;
      if(from=="Super"){
        res =await getApi(`${API_PATH.SUPER_ADMIN.TRANSFER.stcokOut}?restaurantId=${rId}&itemId=${itemData.itemId}&startDate=${startDate}&endDate=${endDate}`)
        setStockOut(res.data.data.stockOut)
      }else{
        res =await getApi(`${API_PATH.ADMIN.TRANSFER.stcokOut}?restaurantId=${rId}&itemId=${itemData.itemId}&startDate=${startDate}&endDate=${endDate}`)
        setStockConsumed(res.data.data.stockOut)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>


            <CCol xs={12}>
              <CIcon size='xxl' onClick={backToPage} style={{ color: "#303c54", cursor: "pointer", marginBottom: "10px" }} icon={cilArrowCircleLeft} ></CIcon>
              <CCard className="mb-4">

                <CCardBody>

                  <h3>Transfer track</h3>
                  <CRow>
                    <CCol xl={6}  >
                      <CCardBody>
                        {from == "Super" && <div className="mb-3">
                          <h6 class="d-inline">Restaurant Name : </h6>
                          {/* <div class="d-inline fw-bolder">{modalData.actualQuantity-modalData.totalSold}</div> */}
                          <div class="d-inline fw-bolder">{rName}</div>
                        </div>}
                        <div className="mb-3">
                          <h6 class="d-inline">Item Name : </h6>
                          {/* <div class="d-inline fw-bolder">{modalData.itemName}</div> */}
                          <div class="d-inline fw-bolder">{itemData.name}</div>
                        </div>
                        <div className="mb-3">
                          <h6 class="d-inline">{from == "Super" ? "Total Stock Out :" : "Total Stock In :"} </h6>
                          {/* <div class="d-inline fw-bolder">{modalData.actualQuantity}</div> */}
                          <div class="d-inline fw-bolder">{from=="Super" ? itemData.totalStockOut : itemData.totalStockIn}</div>
                        </div>
                        <div className="mb-3">
                          <h6 class="d-inline">Remaining In Stock : </h6>
                          {/* <div class="d-inline fw-bolder">{modalData.actualQuantity-modalData.totalSold}</div> */}
                          <div class="d-inline fw-bolder">{itemData.remainingInStock}</div>
                        </div>

                      </CCardBody>
                    </CCol>
                    <CCol xl={6}  >
                      <div className='filter_wrapper'>

                        {/* <DateRangePicker onChange={onChange} value={value} /> */}
                        {/* <DateRangePicker  onApply={applyfunc}>
          <input className='form-control calendar-text'/>
        </DateRangePicker>
   */}
                        {/* <CButton onClick={clearFilter} style={{ marginLeft: "10px" }}>Clear Filter</CButton> */}
                      </div>
                    </CCol>
                  </CRow>

                  <CCardBody className='modal_data_table'>
                    <CRow className='common_main'>
                      <CCol lg={5} className='m-0 p-0'>
                        <CRow>
                          <CCol xs={4}>
                          {from == "Super" ? <h6 style={{ fontWeight: "bold" }}>Stock Out</h6>
                          :
                          <h6 style={{ fontWeight: "bold" }}>Stock In</h6>
                        }
                          </CCol>
                        <CCol xs={8} className='m-0 p-0'>
                            <div className='filter_wrapper' style={{ marginLeft: "25px" }}>

                              {/* <DateRangePicker onChange={onChange} value={value} /> */}
                              <DateRangePicker initialSettings={{ startDate: value[0], endDate:  value[1] }} onApply={applyfunc2}>
                                <input className='form-control calendar-text' />
                              </DateRangePicker>

                              {/* <CButton onClick={clearFilter} style={{ marginLeft: "10px" }}>Clear Filter</CButton> */}
                            </div>
                          </CCol>
                        </CRow>
                        
                        <DataTable
                          columns={columnsForTransction}
                          // data={modalData.inventory}
                          data={stockOut}
                          defaultSortFieldId
                          pagination={5}
                          // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          paginationPerPage={10}
                          highlightOnHover
                        />
                      </CCol>
                      <CCol className='wrapper_of_divider' lg={1}>
                        <div class="divider">

                        </div>
                      </CCol>
                      <CCol lg={6} className='m-0 p-0'>
                        <CRow>
                          <CCol xs={4}>
                            {from == "Super" ? <h6 style={{ fontWeight: "bold" }}>Stock Consumed</h6>
                              :
                              <h6 style={{ fontWeight: "bold" }}>Stock Out</h6>
                            }
                          </CCol>
                          <CCol xs={8} className='m-0 p-0'>
                            <div className='filter_wrapper' style={{ marginLeft: "25px" }}>

                              {/* <DateRangePicker onChange={onChange} value={value} /> */}
                              <DateRangePicker initialSettings={{ startDate: value[0], endDate:  value[1] }} onApply={applyfunc}>
                                <input className='form-control calendar-text' />
                              </DateRangePicker>

                              {/* <CButton onClick={clearFilter} style={{ marginLeft: "10px" }}>Clear Filter</CButton> */}
                            </div>
                          </CCol>
                        </CRow>


                        <DataTable
                          columns={columnsForTransction2}
                          // data={modalData.inventory}
                          data={stockConsumed}
                          defaultSortFieldId
                          pagination={5}
                          // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          paginationPerPage={10}
                          highlightOnHover
                        />
                      </CCol>
                    </CRow>


                  </CCardBody>

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
        {/* {loading ==true && <Loader/>}  */}
      </div>
    </div>
  )
}
