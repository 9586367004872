import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react';

import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import FilterComponent from '../FilterComponent';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
// import { DateRange } from 'react-date-range';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import { DashboardLayout } from '../DashboardLayout';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { getApi } from 'src/api/Api';
import getDateTime, { API_PATH, Loader, makeDate } from 'src/utils/constants';
import Select from 'react-select';



function Consolidated() {

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filteredItems, setfilteredItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [startDate,setStartDate] =useState(makeDate(new Date()))
  const [endDate,setEndDate] =useState(makeDate(new Date()))
  const [loading,setLoading] =useState(true)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);





  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Search Item"} />
    );
  }, [filterText, resetPaginationToggle]);
  const [value, onChange] = useState([new Date(), new Date()]);
  const columns = [

    {
      name: '#',
      cell: (row, index) => index + 1  //RDT provides index by default
    },
 
    {
      name: "Item",
      selector: (row) => row.itemName,
      sortable: true
    },
    {
      name: "Category",
      selector: (row) => row.categoryName,
      sortable: true
    },
    {
      name: "Unit Type",
      selector: (row) => row.unitType,
      sortable: true
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true
    },
    {
      name: "Order At",
      selector: (row) => getDateTime(row.createdAt),
      sortable: true
    },
  ]

  async function getOrder(startDate,endDate) {
    try {
      setLoading(true)
      const res = await getApi(`${API_PATH.SUPER_ADMIN.ORDERS}?startDate=${startDate}&endDate=${endDate}`)
      if (res.status == 200) {
        setOrders(res.data.data.orders)
        // const data =   res.data.data.orders.sort(function(a,b){
        //   return new Date(b.createdAt) - new Date(a.createdAt)
        // })
        // setfilteredItems(data);
        // const data = res.data.data.orders.filter(
        //   item => {
        //     if (!item.createdAt) { return };
        //     const sdate = new Date().setHours(0, 0, 0, 0)
        //     const date = new Date(item.createdAt).getTime()
        //     return date >= sdate;
        //   }
        // )
        setfilteredItems(res.data.data.orders);
        getCategories();
      }
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (filterText != "") {
      const data = orders.filter(
        item => item.itemName && item.itemName.toLowerCase().includes(filterText.toLowerCase()),
      );
      setfilteredItems(data)
    } else {
      setfilteredItems(orders)
    }

  }, [filterText])

  useEffect(() => {
    const sdate = makeDate(value[0]);
    const edate =makeDate(value[1]);
    setStartDate(sdate)
    setEndDate(edate)
    getOrder(sdate,edate);
  }, [value])


  useEffect(() => {
    getOrder(startDate,endDate);
   
  }, [])

  function clearFilter() {
    setfilteredItems(orders)
    // onChange([])
  }


  async function getCategories() {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.CATEGORY)
      if (res.status == 200) {
        const data = res.data.data.category;
        for (let index = 0; index < data.length; index++) {
          data[index].value = data[index].categoryId;
          data[index].label = data[index].categoryName;
        }
        setOptions(data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if(selectedOption!=null && selectedOption.length){
      const res = orders.filter(item => selectedOption.some(e => e.label == item.categoryName));
      setfilteredItems(res)
    }else if(selectedOption==null){
    }
    else if(selectedOption.length==0){
      setfilteredItems(orders)
    }

  }, [selectedOption])


 function handleCallback(start, end, label) {
    const array = [start,end];
    onChange(array);
  }

  function applyfunc(start, date, label) {
    const array = [date.startDate,date.endDate];
    onChange(array);
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>


            <CCol xs={12}>
              <CCard className="mb-4">

                <CCardBody>
                  <h3>Consolidated Orders</h3>
                  <CRow>
                    <CCol xl={6}  >
                      {/* <div style={{width:"40%", marginTop:"20px"}}> */}
                      <Select required
                        placeholder={"Filter based on categories"}
                        isMulti={true}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                      {/* </div> */}
                    </CCol>
                    <CCol xl={6}  >
                      <div className='filter_wrapper'>

                        {/* <DateRangePicker onChange={onChange} value={value} /> */}
                        <DateRangePicker  onApply={applyfunc}>
        <input className='form-control calendar-text'/>
      </DateRangePicker>

                        {/* <CButton onClick={clearFilter} style={{ marginLeft: "10px" }}>Clear Filter</CButton> */}
                      </div>
                    </CCol>
                  </CRow>

                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    pagination={5}
                    highlightOnHover
                    subHeader
                    paginationRowsPerPageOptions={[100,200,300,400]}
                    paginationPerPage={100}
                    subHeaderComponent={subHeaderComponentMemo}
                  />

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
       {loading ==true && <Loader/>} 
      </div>
    </div>

  )
}
export default Consolidated
