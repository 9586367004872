import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cibIntel,
  cilHistory,
  cilGroup
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  // {
  //   component: CNavItem,
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Theme',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Dashboard',
  },
  {
    component: CNavGroup,
    name: 'User',
    to: '/users',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add User',
        to: '/dashboard/user/addusers',
      },
      {
        component: CNavItem,
        name: 'Users List',
        to: '/dashboard/user/users',
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: 'Item Hitory',
  //   to: '/itemhistory',
  //   icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  // },
  {
    component: CNavGroup,
    name: 'Categories',
    to: '/category',
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Categories',
        to: '/dashboard/category/addcategory',
      },
      {
        component: CNavItem,
        name: 'Categories List',
        to: '/dashboard/category/categories',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Restaurants',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Restaurant',
        to: '/dashboard/restaurant/addrestaurant',
      },
      {
        component: CNavItem,
        name: 'Restaurants',
        to: '/dashboard/restaurant/restaurants',
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Items',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Add Items',
        to: '/dashboard/product/addproduct',
      },
      {
        component: CNavItem,
        name: 'Items',
        to: '/dashboard/product/products',
      },
    ]
  },
  {
    component: CNavGroup,
    name: 'Orders',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
    items:[
      {
        component: CNavItem,
        name: 'Consolidated Orders',
        to: '/dashboard/order/consolidated',

      },
    ]
  },
  {
    component: CNavItem,
    name: 'Items History',
    icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
    to: '/dashboard/itemhistory',
  },
  {
    component: CNavGroup,
    name: 'Item Transfer',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
    items:[
      {
        component: CNavItem,
        name: 'Items',
        to: '/dashboard/transfer/addItems',

      },
      {
        component: CNavItem,
        name: 'New Transfer Request',
        to: '/dashboard/transfer/request',

      },
      {
        component: CNavItem,
        name: 'Transfer Track',
        to: '/dashboard/transfer/track',

      },
    ]
  },
  // {
  //   component: CNavGroup,
  //   name: 'Icons',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Notifications',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Toasts',
  //       to: '/notifications/toasts',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Extras',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Docs',
  //   href: 'https://coreui.io/react/docs/templates/installation/',
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  // },
]

export default _nav
