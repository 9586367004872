import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CRow, CButton, CFormInput, CFormText, CCardHeader, CFormLabel } from '@coreui/react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, customStyles2, customStyles3, getFullDateTime, getTrimDate, Loader, makeDate, notifyError, notifySuccess } from 'src/utils/constants';
import CIcon from '@coreui/icons-react';
import { cilReload, cilTrash } from '@coreui/icons';
import ReactModal from 'react-modal';

const data = [
    {
        "itemId": "1",
        "itemName": "Item1",
        "actual_qty": "100",
        "remaining_qty": "30"
    },
    {
        "itemId": "1",
        "itemName": "Item2",
        "actual_qty": "100",
        "remaining_qty": "10"
    },
    {
        "itemId": "1",
        "itemName": "Item3",
        "actual_qty": "100",
        "remaining_qty": "20"
    },
    {
        "itemId": "1",
        "itemName": "Item4",
        "actual_qty": "100",
        "remaining_qty": "40"
    },
    {
        "itemId": "1",
        "itemName": "Item5",
        "actual_qty": "100",
        "remaining_qty": "70"
    }
]
const data2 = [
    {
        "date": "21/3/2023 04:20 PM",
        "placed": "100",
    },
    {
        "date": "22/3/2023 07:20 PM",
        "placed": "50",
    },
    {
        "date": "23/3/2023 09:20 PM",
        "placed": "60",
    },
    {
        "date": "24/3/2023 07:20 PM",
        "placed": "70",
    },
    {
        "date": "25/3/2023 01:20 PM",
        "placed": "63",
    },
    {
        "date": "21/3/2023 04:20 PM",
        "placed": "100",
    },
    {
        "date": "22/3/2023 07:20 PM",
        "placed": "50",
    },
    {
        "date": "23/3/2023 09:20 PM",
        "placed": "60",
    },
    {
        "date": "24/3/2023 07:20 PM",
        "placed": "70",
    },
    {
        "date": "25/3/2023 01:20 PM",
        "placed": "63",
    }
]

export const Transfer = () => {
    const [selelctRestaurant, setselelctRestaurant] = useState(null);
    const [modalBodyTransfer, setModalBodyTransfer] = useState(false);
    const [modalBodyList, setModalBodyList] = useState(false);
    // const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [date, setDate] = useState('2023-04-04');
    const [restaurantOption, setrestaurantOption] = useState([]);
    const [selectItem, setselectItem] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [qty, setQty] = useState(0);
    const [inStockQty, setinStockQty] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [showError, setshowError] = useState(false);
    const navigate = useNavigate();


    const columns = [
        {
            name: <p>#</p>,
            selector: (row, index) => index + 1
        },
        {
            name: "Item",
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: "Total Stock In",
            selector: (row) => row.totalStockIn
            ,
            sortable: true
        },
        {

            name: "Remaining In Stock",
            selector: (row) => <div>
                {/* <CIcon size='lg' style={{marginLeft:"8px"}}  */}
                <span>{row.remainingInStock}</span>
                <i className="fa fa-eye eye"
                    onClick={() => openModal(row, 1)}
                ></i>
            </div>,
        },
        {

            name: "Update Stock",
            selector: (row) => <div>
                {/* <CIcon size='lg' style={{marginLeft:"8px"}}  */}
                <i className="fa fa-edit edit"
                    onClick={() => openModal(row, 2)}
                ></i>
            </div>,
        },
    ];

    const columnsForTransction = [
        {
            name: <p>#</p>,
            selector: (row, index) => index + 1
        },

        {
            name: "Consumer Name",
            
            selector: (row, index) => row.userName
        },
        {
            name: "Date",
            selector: (row) => getTrimDate(row.orderDateTime),
        },
        {
            name: "Item Placed",
            selector: (row) => row.trackQuantity,
        },
    ];

    function openModal(row, check) {
        setModalData(row)
        if (check == 1) {
            // setModalBodyList(true)
            // setModalBodyTransfer(false)
            // setIsOpen(true);
            
            navigate('/admin/transfer/track',{ state: { from: 'admin',itemId: row.itemId,rId:selelctRestaurant.restaurantId,itemData:row,rName:selelctRestaurant.restaurantName } })
            // navigate('/dashboard/transfer/track-quantity',{ state: { from: 'Super',itemId: row.itemId,rId:selelctRestaurant.restaurantId,itemData:row,rName:selelctRestaurant.restaurantName} });
           
        } else {
            setModalBodyList(false)
            setModalBodyTransfer(true)
            setIsOpen(true);
            setQty(0)
            setinStockQty(row.remainingInStock)
        }

        // setOrderId(row.orderId);
    }

    function closeModal() {
        setIsOpen(false);
        setModalBodyList(false)
        setModalBodyTransfer(false)
    }


    useEffect(() => {
        getProifle()
    }, [])


    async function getItems(data) {
        try {
            const res = await getApi(`${API_PATH.ADMIN.TRANSFER.transferItem}?date=${date}&restaurantId=${data.restaurantId}`)
            console.log(res.data.data)
            if (res.status == 200 && res.data.data.items.length) {
                //   setOrders(res.data.data.orders)
                const data = res.data.data.items
                // for (let index = 0; index < data.length; index++) {
                //     data[index].value = data[index].itemId;
                //     data[index].label = data[index].itemName;
                // }
                setTransaction(data)
                setItems(data)
                setSpinner(false)
            }else{
                setTransaction([])
                setItems([])
                setSpinner(false)
            }
        } catch (error) {
            setItemOptions([])
            setSpinner(false)
        }
    }

    const getProifle = async()=>{
        try {
            setSpinner(true)
          const res = await getApi(API_PATH.AUTH.GET_PROFILE);
          if(res.status==200){
            setselelctRestaurant(res.data.data[0])
            getItems(res.data.data[0]);
          }else{
            setSpinner(false)
          }
        } catch (error) {
          console.log(error);
          setSpinner(false)
        }
      }

    async function updateInventry() {
        try {
            // if(qty!=0){
                setSpinner(true)

                const res = await putApi(`${API_PATH.ADMIN.TRANSFER.transferInventory}`,{"itemId":modalData.itemId,"quantity":qty,"restaurantId":selelctRestaurant.restaurantId})
                if (res.status == 200 ) {
                    setSpinner(false)
                    notifySuccess("Inventory Updated Successfully")
                }
                setSpinner(false)
                getProifle()
                closeModal()
            // }else{
            //     notifyError("Qty Should be greater than 0")
            // }
                 
              
   
            
        } catch (error) {
            console.log(error);
            setSpinner(false)
            notifyError(error)
        }
    }

    function checkOrderQty(value) {
        // console.log(value=="")
        if (value == "") {
            setinStockQty(modalData.remainingInStock)
        }

        setQty(value)
        // setinStockQty(inStockQty-value)
    }

    useEffect(() => {
        if(modalData!=null){
            const value = modalData.remainingInStock - qty;
            if(value <0){
                setshowError(true)
            }else{
                setshowError(false)
            }
            setinStockQty(value)
        }
       
    }, [qty])

   

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <CRow>

                        <CCol xl={12}>
                            <CCard className="mb-4">

                                <CCardBody>
                                    <h3>Transfer</h3>
                                  
                                    <CRow className='text-end'>
                                        <CCol xl={12} className="mt-2">
                                        <CButton onClick={getProifle}><CIcon icon={cilReload
                                    }></CIcon> Refresh</CButton>
                                        </CCol>
                                    </CRow>
                                    <DataTable
                                        columns={columns}
                                        data={items}
                                        defaultSortFieldId
                                        pagination={5}
                                        paginationRowsPerPageOptions={[100, 200, 300, 400]}
                                        paginationPerPage={100}
                                        highlightOnHover
                                    />

                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </div>

                <ReactModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalBodyList ? customStyles2 : customStyles3}

                >
                    <button onClick={closeModal} type="button" className="close text-danger" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    {modalBodyList && <div>
                        <CCardHeader>
                            <h4>Track</h4>
                        </CCardHeader>
                        <CCardBody>
                            <div className="mb-3">
                                <h6 class="d-inline">Item Name : </h6>
                                <div class="d-inline fw-bolder">{modalData.itemName}</div>
                            </div>
                            <div className="mb-3">
                                <h6 class="d-inline">Actual : </h6>
                                <div class="d-inline fw-bolder">{modalData.actualQuantity}</div>
                            </div>
                            <div className="mb-3">
                                <h6 class="d-inline">Remaining : </h6>
                                <div class="d-inline fw-bolder">{modalData.actualQuantity- modalData.totalSold}</div>
                            </div>
                        </CCardBody>
                        <CCardBody className='modal_data_table'>
                            <DataTable
                                columns={columnsForTransction}
                                data={modalData.inventory}
                                defaultSortFieldId
                                pagination={5}
                                // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                paginationPerPage={5}
                                highlightOnHover
                            />
                        </CCardBody>
                    </div>}
                    {modalBodyTransfer && <div>
                        <CCardHeader className='text-center mb-3'>
                            <h4>{modalData.itemName}</h4>
                        </CCardHeader>
                        <CCardBody >
                            <div className="mb-3">
                                <h6>Actual Qty  </h6>
                                <div className=" fw-bolder border p-1 border-dark ">{modalData.totalStockIn}</div>
                            </div>
                            <div className="mb-3">
                                <h6 >In Stock  </h6>
                                <div className="fw-bolder border p-1 border-dark ">{inStockQty < 0 ? 0 : inStockQty}</div>
                            </div>
                            <div className="mb-3">
                                <h6 >Consumed  </h6>
                                <input width="50px" type="text" value={qty} onChange={(e) => checkOrderQty(e.target.value)} className={showError ? "d-inline fw-bolder border p-1 border-danger" : "d-inline fw-bolder border p-1 border-dark"} />
                                {showError && <div className='text-danger text_danger'>You can't order more than avaialable stock</div>}
                            </div>
                            <div className='text-center'>
                                <CButton disabled={showError==true || qty==0  ? true : false} onClick={updateInventry} style={{ width: "100%" }}>Update</CButton>
                            </div>
                        </CCardBody>
                    </div>}

                </ReactModal>
                <AppFooter />
                {spinner == true && <Loader />}
            </div>
        </div>
    )
}
