import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    // <CFooter>
    // </CFooter>
    <div></div>
  )
}

export default AppFooter
