import React, { useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormCheck, CFormInput, CRow,CForm,CFormSelect } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { postApi } from 'src/api/Api'
import { API_PATH, notifyError, notifySuccess } from 'src/utils/constants'
// import { DashboardLayout } from 'src/views/DashboardLayout'
import AppFooter from 'src/components/AppFooter'
import AppHeader from 'src/components/AppHeader'
import AppSidebar from 'src/components/AppSidebar'

const AddCategory = () => {
  const [cat,setCat]=useState('');

  const onSubmit =async(e)=>{
    
    e.preventDefault();
    try {
      const res = await postApi(API_PATH.SUPER_ADMIN.CATEGORY,{"categoryName":cat});
      if(res.status==200){
        notifySuccess("Category Added Successfully")
        // setRes(res.data.data);
      }
    } catch (error) {
      console.log(error)
      notifyError(error.response.data.error.error.errors[0].validatorKey=="not_unique" ? "Please Add unique name" : "Error")
    }
    
  }
  return (
    <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add Category</strong>
          </CCardHeader>
          <CCardBody>
            <CForm className="row g-3" onSubmit={onSubmit}>
              <CCol md={12}>
                <CFormInput type="text" id="inputEmail4" name="category" onChange={(e)=>setCat(e.target.value)} label="Enter a category" />
              </CCol>
            
              <div className="d-grid gap-2">
                <CButton type="submit">Add</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      </div>
      <AppFooter />
    </div>
  </div>
     
  )
}

export default AddCategory
