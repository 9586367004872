import React, { useEffect, useState } from 'react'
import { CCard, CCardBody, CCol, CCardHeader, CRow, CButton, CTableHeaderCell, CFormCheck, CInputGroupText, CFormInput, CInputGroup, CForm, CTooltip } from '@coreui/react'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import ReactModal from 'react-modal';
import CIcon from '@coreui/icons-react';
import { cilPen, cilTrash } from '@coreui/icons';
import { useParams } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FilterComponent from '../FilterComponent';
// import { DashboardLayout } from '../DashboardLayout';
import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import { deletetApi, getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, Loader, makeDate, MyMarquee, notifyError, notifySuccess } from 'src/utils/constants';
import { check } from 'prettier';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px"
  },
};

const customStyles2 = {
  rows: {
      clasName:"tst",
      style: {
          minHeight: '50px', // override the row height
      },
  },
  headCells: {
    clasName:"ewew",
      style: {
          paddingLeft: '6px', // override the cell padding for head cells
          paddingRight: '6px',
      },
  },
  cells: {
      style: {
          paddingLeft: '6px', // override the cell padding for data cells
          paddingRight: '6px',
      },
  },
};

const initialData ={
 "acceptedQty":"0",
 "note":""
}

const Orders = () => {
  const [value, onChange] = useState([new Date(), new Date()]);
  const [startDate,setStartDate] =useState(makeDate(new Date()))
  const [endDate,setEndDate] =useState(makeDate(new Date()))
  let { name } = useParams();
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const[orders,setOrders] =useState([]);
  const[filteredItems,setfilteredItems] =useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [updateItems, setupdateItems] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalBodyDelete, setModalBodyDelete] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [ loading,  setLoading] = useState(false);
  const [ called,  setCalled] = useState(true);
  const [inputField , setInputField] = useState(initialData)
  const [modalBodyQty , setModalBodyQty] = useState(false)
  const [news , setNews] = useState([])
  const [Ivalue , setIValue] = useState(0)

  // let filteredItems = [];


  useEffect(()=>{
    if(filterText!=""){
      const data = orders.filter(
        item => item.itemName && item.itemName.toLowerCase().includes(filterText.toLowerCase()),
      );
      setfilteredItems(data)
    }else{
      setfilteredItems(orders)
    }

  },[filterText])

    useEffect(()=>{
    getOrder(name,startDate,endDate);
    getNews(name)
  },[name,orderId==""])

    useEffect(()=>{
      const sdate = makeDate(value[0]);
      const edate =makeDate(value[1]);
      setStartDate(sdate)
      setEndDate(edate)
      setCalled(true)
      getOrder(name,sdate,edate);
  
      // const data = orders.filter(
      //   item => {
      //     if(!item.createdAt) {return};
      //     const date = new Date(item.createdAt)
      //     const sdate = new Date(value[0])
      //     const edate = new Date(value[1])
      //     return (date >= sdate && date <= edate);
      //   }
      // )
      // setfilteredItems(data)
    },[value])


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>

       
      
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Search Item"} />
      </>
    );
  }, [filterText, resetPaginationToggle]);


  function selectOrder(e,data) {
    
    if(e.target.checked){
      const customdata = {
        orderId:data.orderId,
        status:"BOUGHT",
        acceptedQty:(data.quantity),
      }
      setupdateItems(old => [...old, customdata]);
      
    }else{
      setupdateItems(updateItems.filter((i) => (i.orderId !== data.orderId)))
    }
    
  }

  async function updateStatus() {
    try {
      const res = await putApi(API_PATH.SUPER_ADMIN.ORDER_STATUS,updateItems)
      if(res.status==200){
        setupdateItems([])
        getOrder(name,startDate,endDate);
        notifySuccess("Status Updated Successfully")
        uncheckElements()
        getNews(name)
      }
      
    } catch (error) {
      console.log(error)
      notifyError("Something went wrong")
    }
  }

function  checkForNote(data){
 
  if(data!='undefined' && data!='null' && data!=null && data!=""){
    return true
  }else{
    return false
  }
  }

  function uncheckElements()
{
 var uncheck=document.getElementsByTagName('input');
 for(var i=0;i<uncheck.length;i++)
 {
  if(uncheck[i].type=='checkbox')
  {
   uncheck[i].checked=false;
  }
 }
}

  const columns = [
    // {
    //   name: "Order Id.",
    //   selector: (row) => row.orderId,
    //   sortable: true
    // },

    {
      id:"order1",
      className:"test2",
      name: "Action",
      // style: { minWidth: "50px", maxWidth: "50px" },
      selector: (row) => (
        <>
        <input type="checkbox" className='custom_check'
         disabled={row.orderStatus!="NEW-REQUEST" ? true : false }
          onClick={(e)=>selectOrder(e,row)}/>
        </>
      ),
    },
    {
      id:"order2",
      name: "Order Status",
      selector: (row) => <div className={row.orderStatus=="NEW-REQUEST" ? "new_request pulse1" : row.orderStatus=="BOUGHT" ? "bought" :  row.orderStatus==null ? "" : "recieved"}>{row.orderStatus}</div>,
      sortable: true
    }, 
    {
      id:"order3",
      name: "Item",
      selector: (row) => row.itemName,
      sortable: true
    },
    {
      id:"order4",
      name: "Category",
      selector: (row) => row.categoryName,
      sortable: true
    },
    {
      id:"order5",
      name: "Admin Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      id:"order6",
      name: "Unit Type",
      selector: (row) => row.unitType,
      sortable: true
    },
    {
      // id:"order7",
      name: <div className='text-center'>Requested <br></br>Qty</div>,
      // style: { minWidth: "80px", maxWidth: "80px" },
      
      selector: (row) => row.quantity,
    
    },
    {
      // id:"order8",
      name: <div className='text-center'>Updated <br></br>Qty</div>,
      selector: (row) => <div>
      { row.orderStatus == "NEW-REQUEST" && <div style={{display:"inline-block"}}><span style={{display:"inline-block"}} className={`p-1 ${getUpdateValue(updateItems,row.orderId)!=false ? 'deleted-text' : '' }`}>{row.quantity}</span>{getUpdateValue(updateItems,row.orderId)!=false &&  <span>-</span>}  {<div className={getUpdateValue(updateItems,row.orderId)!=false ? 'border-req' : ''} style={{display:"inline-block"}}>{getUpdateValue(updateItems,row.orderId)}</div>}{
      arrayIncludesInObj(updateItems,"orderId",row.orderId) &&
      <CIcon size='lg' style={{marginLeft:"8px"}} onClick={()=>openModal(row,1)}  icon={cilPen}></CIcon>} </div>} 
           
            { row.orderStatus == "BOUGHT" || row.orderStatus == "RECEIVED" ? 
            <>
            {checkForNote(row.receivedQty) ?     <><span className='m-1'>{row.receivedQty}</span>
            { checkForNote(row.noteReceivedQty) ? <CTooltip  content={row.noteReceivedQty}>
        <i className="fa fa-eye eye"></i>
       </CTooltip> : ""}</>  : ""}
        </>: ""}  
      </div>,
      sortable: true
    },
    {
      // id:"order8",
      name: "Order At",
      // style: { minWidth: "100px", maxWidth: "100px" },
      selector: (row) => getDateTime(row.createdAt),
      sortable: true
    },
    {
      name: "Action",
      selector: (row) => (
        <>

       {row.orderStatus=="NEW-REQUEST" && <a style={{cursor:"pointer"}} onClick={()=>openModal(row,0)} ><CIcon icon={cilTrash} class="cil-energy icon text-danger"></CIcon></a>} 
        </>
      ),
      sortable: true
    },
     
  ];

  const arrayIncludesInObj = (arr, key, valueToCheck) => {
    return arr.some(value => value[key] === valueToCheck);
  }

  const getUpdateValue = (arr, valueToCheck) => {
   const data =  arr.filter(value => value["orderId"] === valueToCheck);

   return data.length>0 && `${data[0].acceptedQty}`;
  }

  async function getOrder(oId,startDate,endDate) {
      try {
        setLoading(true)
        const res = await getApi(`${API_PATH.SUPER_ADMIN.ORDERS}?restaurantId=${oId}&startDate=${startDate}&endDate=${endDate}`)
        if(res.status==200){
          setOrders(res.data.data.orders)
          // const data=  res.data.data.orders.filter(
          //   item => {
          //     if(!item.createdAt) {return};
          //     const sdate = new Date().setHours(0,0,0,0)
          //     const date = new Date(item.createdAt).getTime()
          //     return date>=sdate;
          //   }
          // )
          setfilteredItems(res.data.data.orders);
          getCategories()
        }
      } catch (error) {
        setLoading(true)
      }
  }

  async function getNews(id) {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.NEWS+id)
      if(res.status==200){
        const data = res.data.data.ordersNews;
        setNews(data)
      }
    } catch (error) {
      console.log(error)
    }
  }


  async function getCategories() {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.CATEGORY)
      if (res.status == 200) {
        const data = res.data.data.category;
    for (let index = 0; index < data.length; index++) {
      data[index].value = data[index].categoryId;
      data[index].label = data[index].categoryName;
    }
    setOptions(data)
    setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(()=>{
 
    if(selectedOption!=null && selectedOption.length){
      const res = orders.filter(item => selectedOption.some(e => e.label == item.categoryName));
      setfilteredItems(res)
    }else if(selectedOption==null){
    }
    else if(selectedOption.length==0){
      setfilteredItems(orders)
    }
 

  },[selectedOption])


  function openModal(row,check) {
    if(check==1){
      setModalBodyQty(true)
      // setInputField({
      //   acceptedQty:row.quantity,
      //   note:""
      // })
      const getValue =updateItems.find((e)=>e.orderId===row.orderId)
      setIValue(getValue.acceptedQty)
      if(getValue.note!=undefined){
        setInputField({acceptedQty:getValue.acceptedQty,note:getValue.note})
      }else{
        setInputField({acceptedQty:getValue.acceptedQty})
      }
      

      
    }else{
    setModalBodyDelete(true)
    }
    setIsOpen(true);
    setOrderId(row.orderId);
}

function afterOpenModal() {
  // references are now sync'd and can be accessed.
  // subtitle.style.color = '#f00';
}

function closeModal() {
  setIsOpen(false);
}

const onDelete =async()=>{
  try {
    const res = await deletetApi(`${API_PATH.SUPER_ADMIN.ORDERS}/${orderId}`);
    if(res.status==200){
      notifySuccess("Order Deleted Successfully!")
      setOrderId("");
      closeModal()
    }
  } catch (error) {
    notifyError("Something went wrong!")
  }
}

function handleCallback(start, end, label) {
  const array = [start,end];
  onChange(array);
}
function applyfunc(start, date, label) {
  const array = [date.startDate,date.endDate];
  onChange(array);
}



const inputsHandler = (e) =>{
    const { name, value } = e.target;
   setInputField((prevState) => ({
     ...prevState,
     [name]: value,
   }));
  
}

useEffect(()=>{
  updateQuantityChange()
},[inputField])
console.log(inputField,'inputfield')

const updateQuantity =(e)=>{
  e.preventDefault()
  const newState = updateItems.map(obj => {
    console.log(obj,'obj')
    if (obj.orderId === orderId) {
     
      if(inputField.note!=""){
        return { ...obj, acceptedQty: inputField.acceptedQty,note:inputField.note };
      }else{
        return { ...obj, acceptedQty: inputField.acceptedQty};
      }
     
    }
    return obj;
  });
  setupdateItems(newState);
  closeModal()
  
}
const updateQuantityChange =()=>{
  const newState = updateItems.map(obj => {
    console.log(obj,'obj')
    if (obj.orderId === orderId) {
     
      if(inputField.note!=""){
        return { ...obj, acceptedQty: inputField.acceptedQty,note:inputField.note };
      }else{
        return { ...obj, acceptedQty: inputField.acceptedQty};
      }
     
    }
    return obj;
  });
  console.log(newState,'newSatte')
  setupdateItems(newState);
  
}
console.log(updateItems,'uodateIems')
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
        
          <CRow>

            <CCol xl={12}>
              <CCard className="mb-4">

                <CCardBody>
                <h3>Orders</h3>
                {news.length>0 && <MyMarquee message={news}/>}
                <CRow className='mt-2'>
                    <CCol xl={6}>
                      {/* <div style={{width:"40%", marginTop:"20px"}}> */}
                      <Select required
                        placeholder={"Filter based on categories"}
                        isMulti={true}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                      {/* </div> */}
                    </CCol>
                    <CCol xl={6} >
                      <div className='filter_wrapper'> 
                      
                      <DateRangePicker onApply={applyfunc}>
        <input className='form-control calendar-text'  />

      </DateRangePicker>
                  </div>

                  </CCol>
                  <CCol xl={6} className="mt-2">
                  { orders.length>0 &&  <CButton disabled={updateItems.length==0 ? true :false} onClick={updateStatus} >Update Status to BOUGHT</CButton>}
                  </CCol>
                  </CRow>
                  <DataTable
                    className='s_admin_orders'
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    pagination={5}
                    paginationRowsPerPageOptions={[100,200,300,400]}
                    paginationPerPage={100}
                    highlightOnHover
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles = {customStyles2}
                  />

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>

      <ReactModal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              shouldCloseOnOverlayClick={false}

            >
              <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              {modalBodyDelete && <div>
                <CCardHeader>
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete this order ?</p>


                </CCardHeader>
                <CCardBody>
                  <div clasName="clearfix" style={{textAlign:"end"}}>
                    <CButton color='primary' type="button" onClick={closeModal} className="m-2" >Close</CButton>
                    <CButton color="danger"  type="button" onClick={onDelete} className="ml-3">Delete</CButton>
                  </div>
                </CCardBody>
              </div>
              }
              {modalBodyQty && <div>
                <CCardHeader>
                  <h3>Update Quantity</h3>
                </CCardHeader>
                <CCardBody>
                  <div clasName="clearfix" >
                    {/* <CButton color='primary' type="button" onClick={closeModal} className="m-2" >Close</CButton>
                    <CButton color="danger"  type="button" onClick={onDelete} className="ml-3">Delete</CButton> */}

            <CForm className="row" onSubmit={updateQuantity} >
              <CCol md={12} className='accepted'>
                <CFormInput  type="number" required label="Accepted Quantity" name="acceptedQty" value={inputField.acceptedQty} onChange={inputsHandler} />
              </CCol>
              <CCol md={12} className='accepted'>
                <CFormInput type="text"  label="Note" name="note" value={inputField.note} onChange={inputsHandler} />
              </CCol>
              <CButton type='submit' className='mt-3'>Update</CButton>
              </CForm>
                  </div>
                </CCardBody>
              </div>
              }
            </ReactModal>
            {loading ==true && <Loader/>}
    </div>

  )
}

export default Orders
