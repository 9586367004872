import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton, CCardHeader, CFormInput, CForm, CFormSelect, CFormCheck, CSpinner
} from '@coreui/react'
import {
  cilPen, cilTrash
} from '@coreui/icons'
import { DocsExample } from 'src/components'
import DataTable from 'react-data-table-component';
import CIcon from '@coreui/icons-react';
import ReactModal from 'react-modal';
import FilterComponent from 'src/views/FilterComponent';
import { deletetApi, getApi, putApi } from 'src/api/Api';
import getDateTime, { API_PATH, Loader, notifyError, notifySuccess } from 'src/utils/constants';
// import { DashboardLayout } from 'src/views/DashboardLayout';
// import AppFooter from 'src/components/AppFooter';
import AppHeader from 'src/components/AppHeader';
import AppSidebar from 'src/components/AppSidebar';
import Select from 'react-select';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const customStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '55%'
  },
};



function ProductList() {

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalBodyDelete, setModalBodyDelete] = useState(false);
  const [modalBodyUpdate, setModalBodyUpdate] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredItems, setfilteredItems] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
 
  const [items, setItems] = useState([]);
  const [cat, setCat] = useState([]);
  const [loading, setloading] = useState(false) 
  const [unitType,setUnitType] =useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [spinner ,setSpinner] =useState(false)
  const [inputField, setInputField] = useState({
    "itemName": "",
    "unitType": "",
    "categoryId": "",
    "description": "",
    "itemId":"",
  }) 

  // let filteredItems = items.filter(
  //   item => item.itemName && item.itemName.toLowerCase().includes(filterText.toLowerCase()),
  // );

  useEffect(() => {
    if (filterText != "") {
      const data = items.filter(
        item => item.itemName && item.itemName.toLowerCase().includes(filterText.toLowerCase()),
      );
      setfilteredItems(data)
    } else {
      setfilteredItems(items)
    }

  }, [filterText])

  function openModal(check,row) {
    if (check == 1) {
      setModalBodyUpdate(true)
      setModalBodyDelete(false)
      setInputField(row)
    } else {
      setModalBodyDelete(true)
      setModalBodyUpdate(false)
      setInputField(row)
    }
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }



  
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Search Item"} />
    );
  }, [filterText, resetPaginationToggle]);
  
  
  const columns = [

    {
      id:"item1",
      name: '#',
      selector: (row, index) => index + 1  //RDT provides index by default
    },
    {
      id:"item2",
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true
    },
    {
      id:"item3",
      name: "Category",
      selector: (row) => row.categoryname,
      sortable: true
    },
    {
      id:"item4",
      name: "UnitType",
      selector: (row) => row.unitType,
      sortable: true
    },
    {
      id:"item5",
      name: "Updted At",
      selector: (row) => getDateTime(row.createdAt),
      sortable: true
    },
    {
      name: "Action",
      sortable: true,
      selector: (row) => (
        <>
          <a onClick={() => openModal(1,row)}><CIcon size={'lg'} icon={cilPen} className="cil-energy icon text-primary m-1"></CIcon></a>

          <a onClick={() => openModal(0,row)}><CIcon size={'lg'} icon={cilTrash} className="cil-energy icon text-danger m-1"></CIcon></a>
          {/* <i class="cib-twitter icon icon-xxl"></i> */}
        </>
      ),
      sortable: true
    },
  ];

  const getItems = async () => {
    try {
      setSpinner(true)
      const res = await getApi(API_PATH.SUPER_ADMIN.ITEM);
      if (res.status == 200) {
        setItems(res.data.data.items)
        setfilteredItems(res.data.data.items)
        getCats()
       
      }
    } catch (error) {
      setSpinner(false)
    }
  }
  const getCats = async () => {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.CATEGORY)
      if (res.status == 200) {
        setCat(res.data.data.category);

        const data = res.data.data.category;
        for (let index = 0; index < data.length; index++) {
          data[index].value = data[index].categoryId;
          data[index].label = data[index].categoryName;
        }
        setOptions(data)
        getUnitType()
      }
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      "itemName": inputField.itemName,
      "unitType": inputField.unitType,
      "categoryId": inputField.categoryId,
      "description": inputField.description
    }
    setloading(true)
    try {
      const res = await putApi(`${API_PATH.SUPER_ADMIN.ITEM}/${inputField.itemId}`, data);
      if (res.status == 200) {
        setloading(false)
        setIsOpen(false);
        notifySuccess("Updated Successfully")
        // setRes(res.data.data);
      }
    } catch (error) {
      console.log(error)
      setloading(false)
      notifyError("Error")
    }
  }

  const onDelete = async(e)=>{
    e.preventDefault();
    setloading(true)
    try {
      const res = await deletetApi(`${API_PATH.SUPER_ADMIN.ITEM}/${inputField.itemId}`);
      if (res.status == 200) {
        setloading(false)
        setIsOpen(false);
        notifySuccess("Delete Successfully")
        // setRes(res.data.data);
      }
    } catch (error) {
      console.log(error)
      setloading(false)
      notifyError("Error")
    }
  }

  async function getUnitType() {
    try {
      const res = await getApi(API_PATH.SUPER_ADMIN.UNIT_TYPE);
      if(res.status==200){
        if(res.data.data.unitType.length){
          setUnitType(res.data.data.unitType)
          setSpinner(false)
        }
      }
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }



  
  useEffect(() => {
    if(selectedOption!=null && selectedOption.length){
      const res = items.filter(item => selectedOption.some(e => e.label == item.categoryname));
    //  filteredItems=res
      setfilteredItems(res)
    }else if(selectedOption==null){
    }
    else if(selectedOption.length==0){
      setfilteredItems(items)
    }
  
  }, [selectedOption])

  useEffect(() => {
    getItems()
  
  }, [loading==false])



  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>


            <CCol xs={12}>
              <CCard className="mb-4">

                <CCardBody>
                <h3>Items</h3>

                <CRow>
                <CCol xl={6}>
                      {/* <div style={{width:"40%", marginTop:"20px"}}> */}
                      <Select required
                        placeholder={"Filter based on categories"}
                        isMulti={true}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                      {/* </div> */}
                    </CCol>
                    </CRow>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortFieldId
                    pagination={5}
                    highlightOnHover
                    subHeader
                    paginationRowsPerPageOptions={[100,200,300,400]}
                    paginationPerPage={100}
                    subHeaderComponent={subHeaderComponentMemo}
                  />

                </CCardBody>
              </CCard>
            </CCol>
            <ReactModal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalBodyUpdate ? customStyles2 : customStyles}
              contentLabel="Example Modal"

            >
              <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

              {modalBodyUpdate && <div>
                <CCardHeader>
                  <strong>Update Item</strong>
                </CCardHeader>
                <CCardBody>
                  <CForm className="row g-3" onSubmit={onSubmit}>
                    <CCol md={12}>
                    <CFormInput type="text" id="inputEmail4" label="Item Name" name='itemName' required onChange={inputsHandler} value={inputField.itemName} />
                      <CFormInput type="text" id="inputEmail4" label="Description" name='description' required onChange={inputsHandler} value={inputField.description} />
                      <CFormSelect id="inputState" label="unitType" name='unitType' required onChange={inputsHandler} value={inputField.unitType}>
                      {/* <option  >Choose...</option> */}
                  {/* <option value={"Kilogram"}>Kilogram</option>
                  <option value={"Litre"}>Litre</option> */}
                  {unitType && unitType.map((data,index)=>{
                    return (
                      <option key={index} value={data}>{data}</option>
                    )
                  })}
                        </CFormSelect>
                      <CFormSelect id="inputState" label="Category" name='categoryId' required onChange={inputsHandler} value={inputField.categoryId}>
                        <option disabled>Choose...</option>
                        {cat && cat.map((data, index) => {
                          return (
                            <option value={data.categoryId}>{data.categoryName}</option>
                          )
                        })}
                      </CFormSelect>
                    </CCol>



                    <div className="d-grid gap-2">
                    {loading ? <CButton disabled>
                        <CSpinner component="span" size="sm" aria-hidden="true" />
                        Loading...
                      </CButton> : <CButton type="submit">Update</CButton>}
                    </div>
                  </CForm>
                </CCardBody>
              </div>
              }
              {modalBodyDelete && <div>
                <CCardHeader>
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete your account?</p>


                </CCardHeader>
                <CCardBody>
                  <div className="clearfix">
                    <button type="button" onClick={closeModal} className="btn btn-secondary m-3" >Close</button>
                    <button type="button" onClick={onDelete} className="btn btn-danger">Delete</button>
                  </div>

                </CCardBody>
              </div>
              }
            </ReactModal>
          </CRow>
        </div>
        {/* <AppFooter /> */}
        {spinner==true && <Loader/>}
      </div>
    </div>


  )
}
export default ProductList
