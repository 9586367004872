import { Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";


const RequireAuth = ({ allowedRoles }) => {
    const { auth,setAuth } = useAuth();
    return (
        (auth?.roles[0] === allowedRoles)
            ? <Outlet /> 
            : auth?.email
                ? <Navigate to="/404" replace />
                : <Navigate to="/login"  replace />
            
    );
}

export default RequireAuth;